// External Libraries
import React, { useEffect, useState, useMemo, createContext } from 'react';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { useQuery, useMutation, useSubscription } from '@apollo/client';
// Internal Libraries/ files
import {
  convertFilterModelToBE,
  convertSortModelToBE,
  getColumnsForPatientList,
  getDefaultView,
  getNewFormattedData,
  handleUpdateView,
  handleDeleteView,
  setViewsDetails,
  formatEventListData,
  formatPatientListData,
  reconcileColumns,
} from './utility/helper/patientlist/logicPatientList';

import {
  SELECT_PATIENT,
  GET_VIEW,
  UPDATE_VIEW,
  CREATE_VIEW,
  DELETE_VIEW,
  GET_ALL_VIEW,
  EVENTS_QUERY,
  PATIENTS_QUERY,
  SUBSCRIBE_LOCK,
  GET_REPORT_LOCK_DETAILS,
} from './data/queries/patient';
import { SUBSCRIBE_DEVICE_EVENT_GROUP_STATUS } from './data/queries/deviceEventGroup';
import { DEVICE_EVENT_GROUP_STATUS_TYPES_QUERY } from './data/queries/deviceEventGroupStatusType';
import { commonData } from './data/CommonData';
import PatientListGrid from './utility/helper/patientlist/PatientListGrid';
import BConfirm from './components/common/BConfirm';
import BTextField from './components/common/BTextField';
import BButton from './components/common/BButton';
import { useNavigate } from 'react-router-dom';
import { SearchRounded } from '@mui/icons-material';
import { Backdrop, InputAdornment } from '@mui/material';
import {
  generateID,
  sortViewDropdownOptions,
  sortViews,
} from './utility/utilityFunctions';
import SaveViewActions from './components/saveView/SaveViewActions';
import { throttle } from 'lodash';
import { IPatientListData, IGetAllView, IView } from './types/types';
import { PToggleButton } from './components/common/BToggleButton';
import { PORTALS } from './types/constants';
import { AccessPermissionsContext } from './auth/useAccessPermissions';
import BSpinner from './components/common/BSpinner';
import {
  DEFAULT_EVENT_QUEUE_VIEW_PER_USER_ROLE,
  DEFAULT_PATIENT_LIST_VIEW_PER_USER_ROLE,
} from './types/permissions';
import { useSendAuditTrailLogMessage } from './data/queries/auditTrailLogMessage';

export const PatientListCellContentContext = createContext(null);

interface IProps {
  type: 'PATIENT' | 'EVENT';
}

const EventMonitorPatientList = (props: IProps) => {
  const patientListType = props.type; // Check the type of patient list

  const navigate = useNavigate();
  let gridRef = useGridApiRef(); // References the patient list grid

  const USER_ACCESS = React.useContext(AccessPermissionsContext)?.access;
  const ROLE = React.useContext(AccessPermissionsContext)?.role;
  const username = React.useContext(AccessPermissionsContext)?.name;
  
  const { canAccessTechNotes } = USER_ACCESS;

  // mutations
  const { sendAuditMessage } = useSendAuditTrailLogMessage();
  const [updateView] = useMutation(UPDATE_VIEW);
  const [createView] = useMutation(CREATE_VIEW);
  const [deleteView] = useMutation(DELETE_VIEW);
  const [getDeviceLockDetails] = useMutation(GET_REPORT_LOCK_DETAILS);

  const [patientList, setPatientList] = useState(null);
  const [gridCols, setGridCols] = useState([]);
  const [filterBEModel, setFilterBEModel] = useState({});
  const [sortBEModel, setSortBEModel] = useState([]);
  const [filterFEModel, setFilterFEModel] = useState<
    { columnField: string; sort: 'desc' | 'asc' }[]
  >([]);
  const [sortFEModel, setSortFEModel] = useState<
    { field: string; sort: 'desc' | 'asc' }[]
  >([{ field: 'name', sort: 'desc' }]);
  const [searchText, setSearchText] = useState('');
  const [uniqueDeviceIds, setUniqueDeviceIds] = useState([]);
  const [currentView, setCurrentView] = useState<IView | null>(null);
  const [uniqueDeviceEventGroupIds, setUniqueDeviceEventGroupIds] = useState(
    []
  );
  const [inputValue, setInputValue] = useState('');
  const [errorText, setErrorText] = useState('');
  const [activeModal, setActiveModal] = useState<null | string>(null);
  const [toastInfo, setToastInfo] = useState<null | {
    type: string;
    msg: string;
  }>(null);
  const [density, setDensity] = useState('standard');
  const [savedChangedDeviceId, setSavedChangedDeviceId] = useState(''); // for use in animating it later
  const [portal, setPortal] = useState<null | string>('bdm_web');

  const setPatientListDetails = (data: IPatientListData) => {
    if (!data) return;
    const {
      patientList,
      totalCount,
      uniqueDeviceIdsArr = [],
      uniqueDeviceEventGroupIdsArr = [],
    } = data;
    setPatientList(patientList);
    setTotalRowCount(totalCount);
    setUniqueDeviceIds(uniqueDeviceIdsArr);
    setUniqueDeviceEventGroupIds(uniqueDeviceEventGroupIdsArr);
    sendAuditMessage({ action: "DATA_ACCESS", phi_flag: true,
      description: `Populated ${patientListType} list` });
  };

  // This is not only events data, it can be patient data as well
  const fetchMoreEventsData = async (
    sortModel,
    filterModel,
    offset,
    totalRowsPerPage,
    search = '',
    resetPage = false
  ) => {
    setUniqueDeviceIds([]);
    setUniqueDeviceEventGroupIds([]);
    eventFetchMore({
      variables: {
        limit: totalRowsPerPage,
        offset: offset,
        filter: filterModel,
        sort: sortModel,
        search: { value: search, view_id: currentView?.id },
      },
      updateQuery: (p, { fetchMoreResult }) => {
        if (patientListType === 'EVENT') {
          const formattedEventData = formatEventListData(fetchMoreResult);
          setPatientListDetails(formattedEventData);
        } else if (patientListType === 'PATIENT') {
          const formattedPatientData = formatPatientListData(fetchMoreResult);
          if (!formattedPatientData) return;
          setPatientListDetails(formattedPatientData);
        }
        if (resetPage) {
          setPage(0);
        }
      },
    });
  };

  const openDeviceEvent = (event: React.MouseEvent<HTMLElement>, id) => {
    setPopOverId(id);
    setAnchorEl(event.target);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setPopOverId('');
  };

  const handleSortChange = sortItemModel => {
    let sortModel = convertSortModelToBE(sortItemModel, patientListType);
    setSortBEModel(sortModel);
    setSortFEModel(sortItemModel);
    fetchMoreEventsData(
      sortModel,
      filterBEModel,
      0,
      rowsPerPage,
      searchText,
      true
    );
  };

  // BUG 16849: Here we clear values when changing filter keys to avoid values being misapplied to invalid keys
  const validateFilterModel = (filterModel, prevModel) => {
    const newFilterModel = filterModel.map((item, idx) => {
      const prevItem = prevModel[idx];
      if (prevItem && item.columnField !== prevItem.columnField) {
        return { ...item, value: undefined };
      }
      return item;
    });

    return newFilterModel;
  };

  const handleFilterChange = filterModel => {
    const validatedModel = validateFilterModel(filterModel, filterFEModel);

    let convertedModel = convertFilterModelToBE(
      validatedModel,
      patientListType
    );
    setFilterFEModel(validatedModel);
    if (convertedModel) {
      setFilterBEModel(convertedModel);
      fetchMoreEventsData(
        sortBEModel,
        convertedModel,
        0,
        rowsPerPage,
        searchText,
        true
      );
    }
  };

  const handleChangePage = (pageSize, totalRowsCount) => {
    setPage(pageSize);
    fetchMoreEventsData(
      sortBEModel,
      filterBEModel,
      pageSize * totalRowsCount,
      totalRowsCount,
      searchText,
      pageSize === 0 ? true : false
    );
  };

  const handlePortalChange = (
    event: React.MouseEvent<HTMLElement>,
    portal: string
  ) => {
    setPortal(portal);
    const selectedPortal = PORTALS.find(
      selectedPortal => selectedPortal.value === portal
    );
    if (portal === 'cam') {
      window.location.href = selectedPortal.redirect;
    }
  };

  const throttleSetDensity = newDensity =>
    throttle(() => setDensity(newDensity), 50);

  const handleDensityChange = newDensity => {
    throttleSetDensity(newDensity);
  };

  const refreshEventDataGrid = () => {
    fetchMoreEventsData(
      sortBEModel,
      filterBEModel,
      page * rowsPerPage,
      rowsPerPage,
      searchText,
      true
    ); // refreshes the grid
  };

  const handleViewChange = (viewId, setFiltersList) => {
    // TODO setFiltersList is never being called, remove it

    const newView = viewsList.find(v => v.id === viewId);
    if (newView) {
      setCurrentView(newView);
    }
    localStorage.setItem(patientListType + '_view', viewId);

    // call api with different view details
    setPage(0);
    setSearchText('');
    if (viewId === '0') {
      const COLS =
        patientListType === 'EVENT'
          ? DEFAULT_EVENT_QUEUE_VIEW_PER_USER_ROLE[ROLE]
          : DEFAULT_PATIENT_LIST_VIEW_PER_USER_ROLE[ROLE];
      const newCols = getColumnsForPatientList(COLS.columns).cols;
      setGridCols(newCols);
      setDensity(COLS.density);
      setRowsPerPage(COLS.pageSize);
      setViewsDetails(
        filterBEModel,
        filterFEModel,
        sortBEModel,
        sortFEModel,
        setFilterBEModel,
        setFilterFEModel,
        setSortBEModel,
        setSortFEModel
      );
    } else {
      fetchMoreView({
        variables: { id: viewId },
        updateQuery: (p, { fetchMoreResult }) => {
          if (fetchMoreResult.view) {
            const {
              columns,
              density = 'standard',
              pageSize = 10,
            } = fetchMoreResult.view.display_model; // Set fallbacks for density & pageSize if not defined in DB model

            const COLS =
              patientListType === 'EVENT'
                ? DEFAULT_EVENT_QUEUE_VIEW_PER_USER_ROLE[ROLE]
                : DEFAULT_PATIENT_LIST_VIEW_PER_USER_ROLE[ROLE];
            const system_default_columns = COLS.columns;
            const new_columns = reconcileColumns(
              columns,
              system_default_columns
            );

            let { cols } = getColumnsForPatientList(new_columns);

            setViewsDetails(
              fetchMoreResult.view.filter_model.server,
              fetchMoreResult.view.filter_model.client,
              fetchMoreResult.view.sort_model.server,
              fetchMoreResult.view.sort_model.client,
              setFilterBEModel,
              setFilterFEModel,
              setSortBEModel,
              setSortFEModel
            );
            setGridCols(cols);

            setDensity(density);
            setRowsPerPage(pageSize);

            sendAuditMessage({ action: "DATA_ACCESS", phi_flag: true,
              description: `Changed view to: ${fetchMoreResult.view.name}` })
          }
        },
      });

    }

    if (setFiltersList) {
      setFiltersList();
    }
  };

  const searchInView = search => {
    fetchMoreEventsData(
      sortBEModel,
      filterBEModel,
      page * rowsPerPage,
      rowsPerPage,
      search,
      true
    );
  };

  const gotoPatientRegistration = (
    tabActiveName,
    patientId,
    deviceId,
    registered
  ) => {
    selectPatient({
      variables: {
        id: patientId,
        deviceId: deviceId,
        isDeviceRegistered: registered,
      },
    }).then(() => {
      navigate(`/patient/${patientId}`, { state: { tab: tabActiveName } });
    });
  };

  const selectPatientId = (patientId, deviceId, registered) => {
    selectPatient({
      variables: {
        id: patientId,
        deviceId: deviceId,
        isDeviceRegistered: registered,
      },
    });
  };

  const changeLockStatus = (
    deviceId = null,
    isLocked = false,
    timestamp = null
  ) => {
    if (patientList && patientList.length > 0) {
      let newPatientList = [...patientList];
      for (let i = 0; i < newPatientList.length; i++) {
        if (newPatientList[i].deviceId === deviceId) {
          newPatientList[i].deviceLocked = isLocked;
          newPatientList[i].lockedTime = timestamp; // TODO: pretty the date
        }
      }
      setPatientList(newPatientList);
    }
  };

  // Provision for getting the lock status
  const deviceLockInitialStatus = deviceLockStatus => {
    if (patientList) {
      let newPatientList = [...patientList];
      for (let i = 0; i < newPatientList.length; i++) {
        let deviceId = newPatientList[i].deviceId;
        if (deviceId in deviceLockStatus) {
          newPatientList[i].deviceLocked = deviceLockStatus[deviceId]?.locked;
          newPatientList[i].lockedTime = deviceLockStatus[deviceId]?.timestamp; //TODO: pretty the date
        }
      }
      setPatientList(newPatientList);
    }
  };

  const changeDeviceEventStatus = (
    deviceEventId = null,
    current_status = null,
    timestamp = null
  ) => {
    if (patientList && patientList.length > 0) {
      let newPatientList = [...patientList]; // copy by value
      for (let i = 0; i < newPatientList.length; i++) {
        if (newPatientList[i].deviceEventId === deviceEventId) {
          newPatientList[i].current_status = current_status;
        }
      }
      setPatientList(newPatientList); // save to patientList so it can be repopulated
    }
  };

  const [viewAction, setViewAction] = useState({
    action: '',
    open: false,
    title: '',
    value: null,
  });
  const [viewsList, setViewsList] = useState<IView[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState(
    commonData.dataGrid.initial_page_size
  );
  const [page, setPage] = useState(0);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [openPopOverId, setPopOverId] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [chartData, setCharData] = useState(null); // FOR ICM - call fetchTrendChartData function

  let {
    loading: allViewsLoading,
    error: allViewsError,
    data: allViewsData,
  }: IGetAllView = useQuery(GET_ALL_VIEW, {
    variables: { viewType: patientListType.toLowerCase() },
    fetchPolicy: 'network-only',
  });

  let {
    loading: viewLoading,
    error: viewError,
    data: viewData,
    fetchMore: fetchMoreView,
  } = useQuery(GET_VIEW, {
    variables: { id: currentView?.id },
    skip: !currentView?.id || currentView?.id === '0',
    fetchPolicy: 'network-only',
  });

  // get event patient list
  let {
    loading: eventLoading,
    error: eventError,
    data: eventData,
    fetchMore: eventFetchMore,
    refetch,
  } = useQuery(patientListType === 'EVENT' ? EVENTS_QUERY : PATIENTS_QUERY, {
    variables: {
      limit: rowsPerPage,
      offset: 0,
      filter: filterBEModel,
      sort: sortBEModel,
      search: { value: searchText, view_id: currentView?.id || '' },
      hideTechNotes: !canAccessTechNotes,
    },
    fetchPolicy: 'network-only',
    skip: gridCols?.length === 0,
    notifyOnNetworkStatusChange: true,
  });

  const { data: subscribeLockData, loading: subscribeLockLoading } =
    useSubscription(SUBSCRIBE_LOCK, {
      variables: {
        input: {
          deviceIds: uniqueDeviceIds,
        },
      },
      skip: uniqueDeviceIds.length === 0,
    });

  const {
    data: subscribeDeviceEventStatusData,
    loading: subscribeDeviceEventStatusLoading,
  } = useSubscription(SUBSCRIBE_DEVICE_EVENT_GROUP_STATUS, {
    variables: {
      input: {
        deviceEventIds: uniqueDeviceEventGroupIds,
      },
    },
    skip: uniqueDeviceEventGroupIds.length === 0,
  });

  const [selectPatient] = useMutation(SELECT_PATIENT);

  useEffect(() => {
    if (allViewsData && allViewsData.views) {
      const { views } = allViewsData;
      const sortedViews = sortViews(views);

      // TODO move this to constants file or somewhere similar
      const systemView = {
        id: '0',
        default: 'true',
        name: 'System Default',
      };
      if (sortedViews.length === 0) {
        setViewsList([systemView]);
        const COLS =
          patientListType === 'EVENT'
            ? DEFAULT_EVENT_QUEUE_VIEW_PER_USER_ROLE[ROLE]
            : DEFAULT_PATIENT_LIST_VIEW_PER_USER_ROLE[ROLE];
        const newCols = getColumnsForPatientList(COLS.columns).cols;
        setGridCols(newCols);
      } else {
        setViewsList([...sortedViews, systemView]); // merging system view to the views from database
      }
    }
  }, [allViewsData]);

  useEffect(() => {
    const localViewId = localStorage.getItem(patientListType + '_view');
    const localView = viewsList.find(v => v.id === localViewId);
    if (viewsList.length > 0) {
      if (localView) {
        handleViewChange(localView.id, () => {});
      } else {
        const defaultView = getDefaultView(viewsList);
        handleViewChange(defaultView.id, () => {});
      }
    }
  }, [viewsList]);

  useEffect(() => {
    if (patientListType === 'EVENT') {
      const formattedEventData = formatEventListData(eventData);
      setPatientListDetails(formattedEventData);
    } else if (patientListType === 'PATIENT') {
      const formattedPatientData = formatPatientListData(eventData);
      setPatientListDetails(formattedPatientData);
    }
  }, [eventData]);

  useEffect(() => {
    let deviceLockData = subscribeLockData?.reportLockSubscription;
    changeLockStatus(
      deviceLockData?.deviceId,
      deviceLockData?.locked,
      deviceLockData?.timestamp
    );
  }, [subscribeLockData]);

  useEffect(() => {
    getDeviceLockDetails({ variables: { deviceIds: uniqueDeviceIds } }).then(
      (data: any) => {
        let convertedLockStatus = {};

        data?.data?.getReportLocks.forEach(obj => {
          convertedLockStatus[obj['deviceId']] = {
            locked: obj['locked'], // Add more fields later as needed
            timestamp: obj['timestamp'], // Add more fields later as needed
          };
        });
        deviceLockInitialStatus(convertedLockStatus);
      }
    );
  }, [uniqueDeviceIds]);

  useEffect(() => {
    let deviceEventStatusData =
      subscribeDeviceEventStatusData?.deviceEventStatusSubscription;
    changeDeviceEventStatus(
      deviceEventStatusData?.deviceEventId,
      deviceEventStatusData?.current_status,
      deviceEventStatusData?.timestamp
    );
  }, [subscribeDeviceEventStatusData]);

  useEffect(() => {
    setPatientList([]);
    setPage(0);
    setRowsPerPage(10);
  }, [patientListType]);

  const device_event_group_status_types = useQuery(
    DEVICE_EVENT_GROUP_STATUS_TYPES_QUERY
  );

  const handleSaveAsConfirm = async () => {
    const formattedData: any = getNewFormattedData(
      filterBEModel,
      sortBEModel,
      patientListType,
      gridRef,
      currentView,
      inputValue
    );

    const { id, ...createViewData } = formattedData;

    try {
      const response = await createView({
        variables: { input: createViewData },
      });
      if (response?.data?.createView) {
        const newId = response.data.createView;
        const newView = {
          id: newId,
          name: createViewData.name,
          default: 'false',
        };
        const newViewOption = {
          value: newId,
          label: createViewData.name,
        };

        localStorage.setItem(patientListType + '_view', newId);

        const newViewsList = [...viewsList, newView];
        const sortedViews = sortViews(newViewsList);

        setViewsList(sortedViews);
        setCurrentView(newView);
        setToastInfo({ type: 'success', msg: 'Created Successfully!' });
        setActiveModal(null);
      } else {
        throw new Error('Error creating new view');
      }
    } catch (err) {
      // throw an error if we find a duplicate device ID in DB
      const unique = err.graphQLErrors?.some(
        gqlErr => gqlErr.extensions.code === 'UNIQUE_VIOLATION'
      );
      if (unique) {
        setErrorText('A view with that name already exists');
      } else {
        setToastInfo({ type: 'error', msg: 'Sorry! Error occurred' });
        setInputValue('');
        setActiveModal(null);
      }
      console.error('Error saving view as:', err);
    }
  };

  const handleRenameConfirm = async () => {
    setErrorText('');
    try {
      const formattedData = getNewFormattedData(
        filterBEModel,
        sortBEModel,
        patientListType,
        gridRef,
        currentView,
        inputValue
      );
      const data = await handleUpdateView(formattedData, updateView);

      if (data.data.updateView) {
        // update the dropdown
        const oldViewName = currentView.name;
        const newViewName = inputValue;
        const newViewsList = viewsList.map(view =>
          view.id === currentView?.id
            ? {
                name: newViewName,
                id: currentView?.id,
                default: currentView?.default,
              }
            : view
        );
        const sortedNewViewsList = sortViews(newViewsList);

        setViewsList(sortedNewViewsList);
        setCurrentView({
          id: currentView.id,
          name: inputValue,
          default: currentView.default,
        });
        setToastInfo({ type: 'success', msg: 'View renamed Successfully!' });
        setActiveModal(null);
        sendAuditMessage({ action: "RESOURCE_UPDATE", phi_flag: true,
          description: `Renamed view from ${oldViewName} to ${newViewName}` });
      }
    } catch (err) {
      // throw an error if we find a duplicate device ID in DB
      const unique = err.graphQLErrors?.some(
        gqlErr => gqlErr.extensions.code === 'UNIQUE_VIOLATION'
      );
      if (unique) {
        setErrorText('A view with that name already exists');
      } else {
        setToastInfo({ type: 'error', msg: 'Sorry! Error occurred' });
        setInputValue('');
        setActiveModal(null);
      }
      console.error('Error renaming view:', err);
      sendAuditMessage({ action: "RESOURCE_UPDATE_FAILURE", phi_flag: true,
        description: `Failed to rename view, error: ${err}` });
    }
  };

  if (allViewsLoading) {
    return <BSpinner text={'Loading Saved Views...'} />;
  }

  if (allViewsError || viewError || eventError) {
    return (
      <p>
        Error! {viewError?.message} {eventError?.message}
      </p>
    );
  }

  return (
    patientList && (
      <>
        {/* Save View Modal */}
        <BConfirm
          title={'Save view'}
          open={activeModal === 'UPDATE'}
          setOpen={() => {}}
          okCaption={'Yes'}
          onConfirm={async () => {
            setErrorText('');
            const formattedData = getNewFormattedData(
              filterBEModel,
              sortBEModel,
              patientListType,
              gridRef,
              currentView,
              inputValue
            );

            try {
              const response = await updateView({
                variables: { input: formattedData },
              });
              if (response?.data?.updateView) {
                // populate the dropdown with the updated Views list
                const newViewsList = viewsList.map(item =>
                  item.id === currentView?.id
                    ? {
                        id: currentView?.id,
                        name: inputValue,
                        default: currentView?.default,
                      }
                    : item
                );
                const sortedNewViewsList = sortViews(newViewsList);

                setViewsList(sortedNewViewsList);
                setCurrentView({
                  name: inputValue,
                  id: currentView.id,
                  default: currentView.default,
                });
                setToastInfo({ type: 'success', msg: 'Updated Successfully!' });
              } else {
                throw new Error('No updateView in response data');
              }
            } catch (err) {
              console.error('Error:', err);
              setToastInfo({ type: 'error', msg: 'Sorry! Error occurred' });
            }

            setInputValue('');
            setActiveModal(null);
          }}
          cancelEvent={() => {
            setActiveModal(null);
          }}
        >
          <p>Are you sure you want to save your changes?</p>
        </BConfirm>

        {/* Save View As Modal */}
        <BConfirm
          title={'Save as view'}
          open={activeModal === 'NEW'}
          setOpen={() => {}}
          onConfirm={handleSaveAsConfirm}
          cancelEvent={() => {
            setActiveModal(null);
            setErrorText('');
          }}
        >
          <p>Please name your view</p>
          <BTextField
            name='SAVE_AS'
            shrink={true}
            label='View Name'
            value={inputValue}
            handleChange={e => {
              setInputValue(e.target.value);
            }}
            startAdornment={undefined}
            errors={errorText}
          />
        </BConfirm>

        {/* Rename View Modal */}
        <BConfirm
          title={'Rename current view'}
          open={activeModal === 'RENAME'}
          setOpen={() => {}}
          onConfirm={handleRenameConfirm}
          cancelEvent={() => {
            setActiveModal(null);
            setErrorText('');
          }}
        >
          <BTextField
            name='RENAME'
            shrink={true}
            label='View Name'
            value={inputValue}
            handleChange={e => {
              setInputValue(e.target.value);
            }}
            startAdornment={undefined}
            errors={errorText}
          />
        </BConfirm>

        {/* Delete View Modal */}
        <BConfirm
          title={'Delete view'}
          open={activeModal === 'DELETE'}
          setOpen={() => {}}
          okCaption={'Yes'}
          onConfirm={() => {
            handleDeleteView(
              currentView,
              viewsList,
              deleteView,
              handleViewChange,
              setToastInfo,
              setViewsList,
              setCurrentView,
              sendAuditMessage,
            );

            setInputValue('');
            setActiveModal(null);
          }}
          cancelEvent={() => {
            setActiveModal(null);
          }}
        >
          <p>Are you sure you want to delete the view?</p>
        </BConfirm>
        <div>
          {/* When the Event summary window is opened, the backdrop is activated, this dims the background. */}
          <Backdrop open={openPopOverId !== ''} />
          <div
            style={{ display: 'flex', width: '100%', alignItems: 'center' }}
          ></div>
        </div>
        <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
          {/* DataGridActions Search */}
          <form>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: 'auto',
              }}
            >
              <BTextField
                value={searchText}
                label='Search'
                size='small'
                shrink={true}
                style={{ width: '320px' }}
                handleChange={e => {
                  setSearchText(e.target.value);
                }}
                id={generateID('input', 'views', 'search')}
                additionalProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <SearchRounded />
                    </InputAdornment>
                  ),
                  endAdornment: searchText && (
                    <InputAdornment
                      style={{ cursor: 'pointer' }}
                      position='end'
                      onClick={() => {
                        setSearchText('');
                        searchInView('');
                      }}
                    >
                      X
                    </InputAdornment>
                  ),
                }}
              />
              <span className='space-right'></span>
              <BButton
                caption='Search'
                type='blue'
                style={{ marginRight: '30px' }}
                id={generateID('btn', 'views', 'search')}
                clickEvent={e => {
                  e.preventDefault();
                  searchInView(searchText);
                }}
              />
              <PToggleButton
                handleChange={handlePortalChange}
                value={portal}
                toggleButtonList={PORTALS}
                style={{}}
                disable={[]}
              />
            </div>
          </form>
          {/* End */}
          {/* SaveViewActions */}
          <SaveViewActions
            viewsList={viewsList}
            currentView={currentView}
            changeView={handleViewChange}
            showUpdateModal={() => {
              setActiveModal('UPDATE');
              setInputValue(currentView.name);
            }}
            showRenameModal={() => {
              setActiveModal('RENAME');
              setInputValue(currentView.name);
            }}
            showCreateModal={() => {
              setActiveModal('NEW');
              setInputValue('');
            }}
            showDeleteModal={() => {
              setActiveModal('DELETE');
              setInputValue(currentView.name);
            }}
            toastInfo={toastInfo}
            setToastInfo={setToastInfo}
          />
          {/* End */}
        </div>
        <PatientListCellContentContext.Provider
          value={{
            openPopOverId,
            gridCols,
            openDeviceEvent,
            handleClose,
            anchorEl,
            gotoPatientRegistration,
            selectPatientId,
            chartData,
            device_event_group_status_types,
            refreshEventDataGrid,
            setSavedChangedDeviceId,
            savedChangedDeviceId,
            gridRef,
          }}
        >
          <PatientListGrid
            gridRef={gridRef}
            anchorEl={anchorEl}
            filterFEModel={filterFEModel}
            gridCols={gridCols}
            sortFEModel={sortFEModel}
            handleChangePage={handleChangePage}
            handleClose={handleClose}
            handleFilterChange={handleFilterChange}
            handleSortChange={handleSortChange}
            loading={eventLoading || viewLoading || allViewsLoading}
            openDeviceEvent={openDeviceEvent}
            openPopOverId={openPopOverId}
            page={page}
            rowData={patientList}
            rowsPerPage={rowsPerPage}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            totalRowCount={totalRowCount}
            density={density}
            handleDensityChange={handleDensityChange}
            eventType={patientListType}
          />
        </PatientListCellContentContext.Provider>
      </>
    )
  );
};

export default EventMonitorPatientList;
