import {
  EVENT_TYPE_CLINICAL,
  EVENT_TYPE_REPORT,
  EVENT_TYPE_STATUS,
  elColToDBFilterMapping,
  plColToDBFilterMapping,
  IPatient,
  IPatientListData,
  plColToDBSortMapping,
  elColToDBSortMapping,
} from './../../../types/types';
import { GridColumnMenuContainer } from '@mui/x-data-grid-pro';
import {
  GridFilterMenuItem,
  HideGridColMenuItem,
  GridColumnsMenuItem,
  getGridStringOperators,
} from '@mui/x-data-grid-pro';
import { getPatientListColDetails } from './plcomponents';
import dateRangeOperators from './../../../components/customGridFilters/DateFilterComponent';
import EventsDeviceFilterOperators from './../../../components/customGridFilters/EventsDeviceFilterComponent';
import _isNil from 'lodash/isNil';
import _ from 'lodash';
import { getDiffDays } from '../../utilityFunctions';
import { IView, filterKeyMap } from '../../../types/types';
import { MAP_PATIENT_EVENT_LIST_COL_LABELS } from '../../../types/constants';

const filterOperators = getGridStringOperators().filter(({ value }) =>
  ['contains'].includes(value)
);

interface Report {
  createdAt: string;
  updatedAt: string;
  type: string;
  currentState: String;
  fileLink?: string;
  id: string;
  viewed: Boolean;
}
const getReportList = reports => {
  let reportList: Partial<Report>[] = [];
  let reportStateList: Partial<Report>[] = [];
  let eventListReports = [];
  let summaryListReports = [];

  if (reports) {
    for (let i = 0; i < reports.length; i++) {
      let item = reports[i];
      let reportItem: Partial<Report> = {};
      reportItem.createdAt = item?.created_at;
      reportItem.updatedAt = item?.updated_at;
      reportItem.type = item.reportType?.name;
      reportItem.currentState = item?.current_state;
      reportItem.fileLink = item?.file_link;
      reportItem.id = item?.id;
      reportItem.viewed = item?.viewed;
      if (
        reportItem.currentState === 'published' ||
        reportItem.currentState === 'accepted'
      ) {
        if (reportItem?.type === 'event') {
          eventListReports.push(reportItem);
        } else {
          // rest are the summary reports
          summaryListReports.push(reportItem);
        }
      } else {
        reportStateList.push(reportItem);
      }
    }
    // Temporary solution, eventually BE will have a filter option to just send the most recent one
    summaryListReports?.sort((b, a) => a.createdAt - b.createdAt).splice(1); // get the most recent summary report
    eventListReports?.sort((b, a) => a.createdAt - b.createdAt);
    reportList = [...eventListReports, ...summaryListReports]; // merge both reports - event reports first followed by most recent summary report
  }

  return { eventListReports, summaryListReports, reportStateList };
};

// Build the structure which will be used to populate the data in the patient list
export const flattenPatientListStructure = (patientListFromBE: any) => {
  let patientList = [];
  let uniqueDeviceIdsArr = [];
  let uniqueDeviceEventGroupIdsArr = [];
  let dataTransmissionThreshold;
  patientListFromBE.map(data => {
    dataTransmissionThreshold =
      data?.devices[0]?.deviceEventSettings[0]?.deviceEventSettingThresholds[0]
        ?.value;
    patientList.push({
      id: data.id,
      patientId: data.id,
      patientIdentifier: data.patient_identifier,
      firstName: data.first_name,
      lastName: data.last_name,
      name: `${data.last_name}, ${data.first_name}`,
      age: data.age,
      sex: data.sex,
      dateOfBirth: data.date_of_birth,
      phone: data.phone,
      indications: data.indications.map(item => item.desc).join(','),
      deviceId: data?.devices[0]?.deviceId || null,
      ...getReportList(data?.devices[0]?.reports),
      deviceSettingId: data?.devices[0]?.id || null,
      prescribingProvider: data?.devices[0]?.prescribing_provider || null,
      registered: data?.devices[0]?.active || false,
      technicianNotes: data?.history
        ?.map(item => item.description?.text)
        .join(', '),
      activation_status:
        data?.patientNewestDeviceView?.device_status_with_flags,
      device_status_with_flags:
        data?.patientNewestDeviceView?.device_status_with_flags,
      deviceStatus: getDeviceStatusState(
        data?.devices[0]?.activation_status,
        data?.devices[0]?.deviceStatus,
        dataTransmissionThreshold,
        data?.patientNewestDeviceView?.device_status_with_flags
      ),
      orgContactDetails: {
        orgName: data?.organization?.name,
      },
    });

    if (data?.devices[0]?.deviceId) {
      uniqueDeviceIdsArr.push(data?.devices[0]?.deviceId);
    }
  });

  return { patientList, uniqueDeviceIdsArr, uniqueDeviceEventGroupIdsArr };
};

// helper function to get the event values
const getEventValue = (abbr, val, eventsTriggered) => {
  let value = '';
  if (eventsTriggered) {
    if (abbr === 'BASELINE') {
      value = 'Baseline Received';
    } else {
      value = val;
    }
  } else {
    if (abbr !== 'BASELINE') {
      value = 'No Event';
    } else if (abbr === 'BASELINE') {
      if (val === 1) {
        value = 'No Baseline';
      } else if (val === 2) {
        value = 'Baseline Received';
      } else {
        value = 'NA';
      }
    } else {
      value = val;
    }
  }
  return value;
};

// forms the events for clinical, device status and report due events
const formDifferentEvents = (
  event,
  value,
  patientEvent,
  eventsTriggered = true
) => {
  let type = event.deviceEventSettingType.event_type;
  let events = {};
  events = {
    // create event object
    severity: eventsTriggered ? event.severity : 'off',
    name: event.deviceEventSettingType.name,
    abbr: event.deviceEventSettingType.abbr,
    value: getEventValue(
      event.deviceEventSettingType.abbr,
      value,
      eventsTriggered
    ), //event.severity == "off" && event.abbr != "BASELINE" ? "No Event" : value,
    order: event.deviceEventSettingType.display_order,
    type: event.deviceEventSettingType.abbr_type,
  };
  if (type === EVENT_TYPE_CLINICAL) {
    if (event.deviceEventSettingType.abbr_type === 'string') {
      // clinical events ARR
      patientEvent.patientEventsList.push(events);
    } else {
      patientEvent.deviceEventsList.push(events);
    }
  } else if (type === EVENT_TYPE_STATUS) {
    patientEvent.deviceStatusEventsList.push(events);
  } else if (type === EVENT_TYPE_REPORT) {
    patientEvent.reportDueEventsList.push(events);
    // further break down into different report types.
  } else {
    console.log(
      'Error, events type is not identified: device group number: ',
      patientEvent['deviceEventId']
    );
  }
};

export const getDeviceStatusState = (
  activation_status,
  deviceValues,
  dataTransmissionThreshold,
  device_status_with_flags
) => {
  let activationStatus = activation_status;
  let activationStatusTooltip = '';
  let switchPatchFlag = false;
  let switchPatchToolTip = '';
  let dataTransmissionFlag = false;
  let dataTransmissionToolTip = '';

  if (!device_status_with_flags) {
    return {
      activationStatus,
      switchPatchFlag,
      dataTransmissionFlag,
      activationStatusTooltip,
      switchPatchToolTip,
      dataTransmissionToolTip,
    };
  }

  const split = device_status_with_flags
    ? device_status_with_flags.split('+')
    : '';

  for (let i = 0; i < split.length; i++) {
    switch (split[i].trim()) {
      case 'No Data':
        dataTransmissionFlag = true;
        let daysSince = getDiffDays(
          deviceValues[0]?.last_transmission_timestamp
        );
        let displayDaysSince = Math.ceil(daysSince);
        activationStatusTooltip = `data received  ${displayDaysSince} days ago`;
        if (deviceValues[0]?.last_transmission_timestamp) {
          if (daysSince > dataTransmissionThreshold) {
            // Since the days the last tranmission occurred passed the threshold, raise the flag
            dataTransmissionToolTip = `data received  ${displayDaysSince} days ago`;
          }
        }
        break;
      case 'Change Patch':
        switchPatchFlag = true;
        switchPatchToolTip = 'switch patch';
        break;
      default:
        activationStatus = split[i];
    }
  }

  return {
    activationStatus,
    switchPatchFlag,
    dataTransmissionFlag,
    activationStatusTooltip,
    switchPatchToolTip,
    dataTransmissionToolTip,
  };
};

export const getOrgContactDetails = org => {
  return {
    orgName: org?.name,
    dayTimePhone: org?.contact?.daytime_phone,
    dayTimeRange: `${org?.contact?.daytime_hours_start}  ${org?.contact?.daytime_hours_end}`,
    afterHoursPhone: org?.contact?.after_hours_phone,
    afterHoursRange: `${org?.contact?.daytime_hours_end}  ${org?.contact?.daytime_hours_start}`,
    fax: org?.contact?.fax,
    prescribingProviderName: `${org?.providers[0]?.last_name} ${org?.providers[0]?.first_name}`,
    prescribingProviderPhone: org?.providers[0]?.phone,
    prescribingProviderEmail: org?.providers[0]?.email,
  };
};

// Build the structure which will be used to populate the data
// i/p: data => response from the API call to fetch the patient list
// TODO what if check the data
export const flattenEventListStructure = (eventListFromBE: any) => {
  let patientList: Array<IPatient> = [];
  let patientEvent: any = {}; // stores unique clinical events
  let uniqueEvents = {};
  let uniqueDeviceIds = new Set();
  let uniqueDeviceIdsArr = [];
  let uniqueDeviceEventGroupIds = new Set();
  let uniqueDeviceEventGroupIdsArr = [];
  let eventThresholdObj = {}; // Stores the thresholds for all the events
  eventListFromBE.forEach((item, eventIndex) => {
    // collection of all the events
    patientEvent = {};
    uniqueEvents = {}; // TODO should this be a set?
    // initialize all the events to empty array
    patientEvent.patientEventsList = [];
    patientEvent.deviceStatusEventsList = [];
    patientEvent.reportDueEventsList = [];
    patientEvent.deviceEventsList = [];

    patientEvent['id'] = item.id + eventIndex; // event id - adding eventIndex gives unique React key in case event id is not unique
    patientEvent['startTime'] = item.start_time; // event start time
    patientEvent['endTime'] = item.end_time; // event end time
    patientEvent['deviceEventId'] = item.id; // unique per group
    patientEvent['eventsType'] = item.events_type; // type of the event - for eg clinical, reportdue etc
    patientEvent['deviceSettingId'] = item.device.id;
    patientEvent['activation_status'] = item.device.activation_status; // Incomplete/Pending/Active/Deactivated
    patientEvent['deviceLocked'] = false; // By default it not locked
    patientEvent['current_status'] = item.current_status;
    patientEvent['notification_date'] = item.created_at; // the time the record entered the database

    let dataTransmissionThresholdSetting = 0;

    // those which had events:
    item.deviceEvents.forEach(deviceEventItem => {
      // divide based on the event types
      if (deviceEventItem?.deviceEventSetting?.severity === 'on') {
        if (
          deviceEventItem.deviceEventSetting.deviceEventSettingType.abbr ===
          'DATA_TRANSMISSION'
        ) {
          dataTransmissionThresholdSetting =
            deviceEventItem?.deviceEventSetting?.deviceEventSettingThresholds[0]
              ?.value;
        }
        uniqueEvents[
          deviceEventItem.deviceEventSetting.deviceEventSettingType.abbr
        ] = 0; // store in unique events to avoid storing same event as "No event"
        formDifferentEvents(
          deviceEventItem.deviceEventSetting,
          deviceEventItem.value,
          patientEvent,
          true
        );
      }
    });

    patientEvent['deviceStatus'] = getDeviceStatusState(
      item.device.activation_status,
      item.device.deviceStatus,
      dataTransmissionThresholdSetting,
      item.device.deviceStatusWithFlagsView?.device_status_with_flags
    );

    // those whose events has not occured but the events are not turned off
    item.device &&
      item.device.deviceEventSettings.forEach(deviceEventSettingsItem => {
        // divide based on the event types
        let abbr = deviceEventSettingsItem.deviceEventSettingType.abbr;

        if (!(abbr in uniqueEvents)) {
          if (
            deviceEventSettingsItem.deviceEventSettingType.event_type ==
            patientEvent['eventsType']
          ) {
            let value =
              deviceEventSettingsItem.deviceEventSettingThresholds.length > 0
                ? deviceEventSettingsItem.deviceEventSettingThresholds[0].value
                : 'NA';
            formDifferentEvents(
              deviceEventSettingsItem,
              value,
              patientEvent,
              false
            );
          }
        }
        eventThresholdObj[abbr] =
          deviceEventSettingsItem.deviceEventSettingThresholds;
      });

    const sortFn = (a, b) => Number(a.order) - Number(b.order);
    // Events are formed, now lets sort by the display order
    patientEvent['deviceEventsList'] =
      patientEvent.deviceEventsList.sort(sortFn);
    patientEvent['patientEventsList'] =
      patientEvent.patientEventsList.sort(sortFn);
    patientEvent['deviceStatusEventsList'] =
      patientEvent.deviceStatusEventsList.sort(sortFn);
    patientEvent['reportDueEventsList'] =
      patientEvent.reportDueEventsList.sort(sortFn);

    // patients details
    const patientDetails = item.device.patients[0];
    patientEvent['name'] =
      patientDetails.last_name + ', ' + patientDetails.first_name;
    patientEvent['lastName'] = patientDetails.last_name;
    patientEvent['firstName'] = patientDetails.first_name;
    patientEvent['patientId'] = patientDetails.id;
    patientEvent['patientIdentifier'] = patientDetails.patient_identifier;
    patientEvent['age'] = patientDetails.age;
    patientEvent['sex'] = patientDetails.sex;
    patientEvent['organization'] = patientDetails.organization?.name;
    patientEvent['dateOfBirth'] = patientDetails.date_of_birth;
    patientEvent['indications'] = patientDetails.indications
      .map(item => item.desc)
      .join(',');
    patientEvent['orgContactDetails'] = getOrgContactDetails(
      patientDetails.organization
    );
    patientEvent['referringProvider'] = item.device.referring_provider;
    patientEvent['deviceId'] = item.device.deviceId;
    if (item?.device?.deviceId) uniqueDeviceIds.add(item?.device?.deviceId);
    uniqueDeviceEventGroupIds.add(item?.deviceEvent?.id);
    patientEvent['registered'] = true; // obvious - no device no events
    patientEvent['technicianNotes'] =
      item.device.patients[0]?.history
        ?.map(item => item.description?.text)
        .join(', ') || '-';
    let { eventListReports, summaryListReports, reportStateList } =
      getReportList(item.device.reports);
    patientEvent['eventListReports'] = eventListReports;
    patientEvent['summaryListReports'] = summaryListReports;
    patientEvent['reportStateList'] = reportStateList;
    patientEvent['eventThresholdObj'] = eventThresholdObj;
    patientList.push(patientEvent);
    if (item) uniqueDeviceEventGroupIds.add(item.id);
  });

  uniqueDeviceIdsArr = Array.from(uniqueDeviceIds);
  uniqueDeviceEventGroupIdsArr = Array.from(uniqueDeviceEventGroupIds);
  return {
    patientList,
    uniqueDeviceIdsArr,
    uniqueDeviceEventGroupIdsArr,
  };
};

export const formatPatientListData = (APIResult): IPatientListData => {
  const data = APIResult?.paginatedPatients?.collection;
  if (!data) return;

  let { patientList, uniqueDeviceIdsArr, uniqueDeviceEventGroupIdsArr } =
    flattenPatientListStructure(data);

  return {
    patientList,
    uniqueDeviceIdsArr,
    uniqueDeviceEventGroupIdsArr,
    totalCount: APIResult.paginatedPatients.totalCount,
  };
};

export const formatEventListData = (APIResult): IPatientListData => {
  const data = APIResult?.paginatedDeviceEventGroups?.collection;
  if (!data) return;

  let { patientList, uniqueDeviceIdsArr, uniqueDeviceEventGroupIdsArr } =
    flattenEventListStructure(data);

  return {
    patientList,
    uniqueDeviceIdsArr,
    uniqueDeviceEventGroupIdsArr,
    totalCount: APIResult.paginatedDeviceEventGroups.totalCount,
  };
};

export const getSortFE = sortFE => {
  let result = [];
  if (sortFE && sortFE.length > 0) {
    for (let i = 0; i < sortFE.length; i++) {
      result.push({
        field: sortFE[i].columnField,
        sort: sortFE[i].sort,
      });
    }
    return result;
  }
};

export const getDefaultView = viewsList => {
  return _.find(viewsList, ['default', 'true']);
};

export const setViewsDetails = (
  filterBE,
  filterFE,
  sortBE,
  sortFE,
  setFilterBEModel,
  setFilterFEModel,
  setSortBEModel,
  setSortFEModel
) => {
  setFilterBEModel(Object.assign({}, filterBE) || []);
  setFilterFEModel(() => filterFE);
  setSortBEModel([...sortBE] || []);
  setSortFEModel(getSortFE(sortFE));
};

export const handleDeleteView = async (
  currentView,
  viewsList: IView[],
  deleteView,
  changeView,
  setToastInfo,
  setViewsList,
  setCurrentView,
  sendAuditMessage,
) => {
  try {
    if (currentView?.default === 'true') {
      throw new Error('Cannot delete default view');
    }

    const response = await deleteView({ variables: { id: currentView.id } });

    const deletedId = response.data.deleteView;
    // delete the view instance from viewslist
    if (!deletedId) throw new Error('No deleteView ID');
    const filteredViewsList = viewsList.filter(item => item.id !== deletedId);

    const firstView = filteredViewsList[0];
    setViewsList([...filteredViewsList]);
    setCurrentView(firstView);
    changeView(firstView.id);
    setToastInfo({ type: 'success', msg: 'Deleted Successfully!' });
    sendAuditMessage({ action: "RESOURCE_DELETION", phi_flag: true,
      description: `Deleted viewId ${deletedId}` });
  } catch (err) {
    console.error('Error deleting view:', err);
    setToastInfo({ type: 'error', msg: 'Sorry! Error occurred' });
    sendAuditMessage({ action: "RESOURCE_DELETION_FAILURE", phi_flag: true,
      description: `Failed to delete view, error: ${err}` });
  }
};
const formatFilterModel = (filterBEModel, gridRef) => {
  let filterModel = {};

  // filter model formation
  filterModel['server'] = filterBEModel;
  filterModel['client'] = gridRef.current.state.filter.filterModel.items;

  return filterModel;
};

const formatSortModel = (sortBEModel, gridRef) => {
  let sortModel = {};

  // sort model formation
  sortModel['server'] = sortBEModel;
  sortModel['client'] = gridRef.current.state.sorting.sortModel.map(item => {
    return {
      columnField: item.field,
      sort: item.sort,
    };
  });

  return sortModel;
};

const formatDisplayModel = gridRef => {
  let displayModel = {};

  //display model formation
  let displayLookup = gridRef.current.state.columns.lookup;
  let colOrderMap = {};
  let order = gridRef.current.state.columns.all.map((col, index) => {
    colOrderMap[col] = index;
  });
  displayModel['columns'] = Object.keys(displayLookup).map(key => {
    return {
      align: displayLookup[key]['align'],
      field: displayLookup[key]['field'],
      filterable: displayLookup[key]['filterable'],
      sortable: displayLookup[key]['sortable'],
      hideable: displayLookup[key]['hideable'],
      resizeable: displayLookup[key]['resizeable'],
      hide: displayLookup[key]['hide'],
      label: displayLookup[key]['label'],
      header_name: displayLookup[key]['field'],
      order: colOrderMap[displayLookup[key]['field']],
      width: parseInt(displayLookup[key]['width']),
      minWidth: parseInt(displayLookup[key]['minWidth']),
      maxWidth: parseInt(displayLookup[key]['maxWidth']),
      disableColumnMenu: displayLookup[key]['disableColumnMenu'],
    };
  });

  displayModel['pageSize'] = gridRef?.current?.state?.pagination?.pageSize;
  displayModel['density'] = gridRef?.current?.state?.density?.value;

  return displayModel;
};

const formatNewViewParam = (
  filterModel,
  sortModel,
  displayModel,
  viewAction,
  viewType,
  currentView
) => {
  let updateViewParam = {};

  updateViewParam['filter_model'] = filterModel;
  updateViewParam['sort_model'] = sortModel;
  updateViewParam['display_model'] = displayModel;
  updateViewParam['name'] = viewAction;
  updateViewParam['view_type'] = viewType;
  updateViewParam['id'] = currentView.id.split('_')[0];

  return updateViewParam;
};

export const getNewFormattedData = (
  filterBEModel,
  sortBEModel,
  patientListType,
  gridRef,
  currentView,
  inputValue
) => {
  const filterModel = formatFilterModel(filterBEModel, gridRef);
  const sortModel = formatSortModel(sortBEModel, gridRef);
  const displayModel = formatDisplayModel(gridRef);

  const newViewParams = formatNewViewParam(
    filterModel,
    sortModel,
    displayModel,
    inputValue,
    patientListType.toLowerCase(),
    currentView
  );

  return newViewParams;
};

export const handleUpdateView = async (data, updateView) => {
  if (data.currentView?.default === 'true') {
    throw new Error('Cannot update default view');
  }
  const response = await updateView({ variables: { input: data } });

  return response;
};

export const formECGViewerURL = (url, deviceId) => {
  console.log(url + deviceId);
  if (deviceId) return url + deviceId;
  return '/404';
};

// Custom column menu in data grid
export const CustomColumnMenu = props => {
  const { hideMenu, currentColumn } = props;
  return (
    <GridColumnMenuContainer
      open={true}
      hideMenu={hideMenu}
      currentColumn={currentColumn}
    >
      <GridFilterMenuItem onClick={hideMenu} column={currentColumn} />
      <HideGridColMenuItem onClick={hideMenu} column={currentColumn} />
      <GridColumnsMenuItem onClick={hideMenu} column={currentColumn} />
    </GridColumnMenuContainer>
  );
};

export const invalidFilterModel = filterModel => {
  for (let i = 0; i < filterModel.length; i++) {
    if (!('value' in filterModel[i]) || _isNil(filterModel[i]['value'])) {
      return true;
    }
    if (filterModel[i]['columnField'] == 'date_of_birth') {
      if (
        filterModel[i]['value'].length != 2 ||
        _isNil(filterModel[i]['value'][0]) ||
        _isNil(filterModel[i]['value'][1])
      ) {
        return true;
      }
    }
  }
  return false;
};

export const getColumnsForPatientList = colData => {
  let cols = [];
  let sortModel = [];
  for (let col = 0; col < colData.length; col++) {
    let data = {
      headerName:
        MAP_PATIENT_EVENT_LIST_COL_LABELS[colData[col].header_name] || 'NA',
      width: colData[col].width.toString(),
      hide: String(colData[col].hide).toLowerCase() === 'true',
      hideable: colData[col].hideable,
      sortable:
        colData[col].header_name === 'organization'
          ? true
          : colData[col].sortable, // todo: remove this once database has sortable true for organization
      filterable:
        colData[col].header_name === 'tviewer_due'
          ? true
          : colData[col].filterable,
      resizeable: colData[col].resizeable,
      minWidth: colData[col].minWidth || 200,
      field: colData[col].header_name,
      headerClassName: 'patient-list-title',
      order: colData[col].order,
      type: 'multiselect',
      renderCell: params =>
        getPatientListColDetails(colData[col].header_name, params),
      filterOperators,
    };

    if (colData[col].header_name === 'date_of_birth') {
      data['filterOperators'] = dateRangeOperators;
    } else if (
      [
        'current_status',
        'tevents',
        'tdevice_status',
        'tviewer_due',
        'history',
      ].includes(colData[col].header_name)
    ) {
      data['filterOperators'] = EventsDeviceFilterOperators;
    }
    cols.push(data);
  }
  cols.sort((a, b) => a.order - b.order);
  return { cols, sortModel };
};

// TODO are FilterObjectTypes exported from GraphQL?
export interface IFilterObject {
  id: number;
  columnField: string;
  operatorValue: 'contains' | 'equals' | 'between' | 'exists';
  value?: any;
}

export type IFilter = IFilterObject | { _or: IFilterObject[] };

const reportAndViewerDueLogic = (
  filterObj: IFilterObject,
  filterFieldNames: string[]
) => {
  let reportValues = filterObj.value;

  // Uses a set to avoid duplicate search vals
  const reportTypeVals = new Set();
  const reportStatusVals = new Set();

  reportValues.forEach((reportVal: string[]) => {
    const [reportType, reportStatus] = reportVal;

    // don't add if value is an empty string
    if (reportType) {
      // reportStatus may be a comma separated list of vals,
      // split on comma to get all of them and then
      // push them all to the set
      const allVals = reportType.split(',');
      allVals.forEach(val => {
        reportTypeVals.add(val);
      });
    }

    if (reportStatus) {
      reportStatusVals.add(reportStatus);
    }
  });

  const reportTypeFilterObj = {
    ...filterObj,
    columnField: filterFieldNames[0],
    value: Array.from(reportTypeVals),
  };

  const reportStatusFilterObj = {
    ...filterObj,
    columnField: filterFieldNames[1],
    value: Array.from(reportStatusVals),
  };

  const reportFilterValues = [reportTypeFilterObj, reportStatusFilterObj];

  // don't add filter if value array is empty
  const output = reportFilterValues.filter(filterObj => filterObj.value.length);

  return {
    _or: output,
  };
};

const getFilterFromFieldNames = (
  filterObj: IFilterObject,
  filterFieldNames: string[]
): IFilter => {
  // handle special cases for viewer_due and history (reports)
  if (
    filterObj['columnField'] === 'tviewer_due' ||
    filterObj['columnField'] === 'history'
  ) {
    return reportAndViewerDueLogic(filterObj, filterFieldNames);
  }

  const filters = filterFieldNames.map(fieldName => ({
    ...filterObj,
    columnField: fieldName,
  }));

  if (filters.length > 1) {
    return { _or: filters };
  } else {
    return filters[0];
  }
};

const containsEmptyDateRangeValues = filterObj => {
  return (
    !filterObj?.value ||
    !Array.isArray(filterObj.value) ||
    filterObj.value.length != 2 ||
    filterObj.value.some(val => !val)
  );
};

const containsEmptyMultiSelectValues = filterObj => {
  return (
    !filterObj?.value ||
    !filterObj.value.length ||
    filterObj.value.some(val => !val)
  );
};

const onlyFiltersWithValues = filterObj => {
  if (
    filterObj['columnField'] === 'date_of_birth' &&
    containsEmptyDateRangeValues(filterObj)
  ) {
    return false;
  }

  if (
    filterObj['columnField'] === 'tevent_date' &&
    (!filterObj?.value || filterObj?.value?.includes(undefined))
  ) {
    return false;
  }

  const multiselectFields = [
    // does not include date of birth
    'tdevice_status',
    'tviewer_due',
    'history',
    'tevents', // EVENT only
    'tviewer', // EVENT only
    'current_status', // EVENT only
  ];
  if (
    multiselectFields.includes(filterObj['columnField']) &&
    containsEmptyMultiSelectValues(filterObj)
  ) {
    return false;
  }

  if (!filterObj?.value) return false;

  return true;
};

export const convertFilterModelToBE = (
  filterModel: IFilterObject[],
  type: 'PATIENT' | 'EVENT'
) => {
  const filtersWithValues = filterModel.filter(onlyFiltersWithValues);

  const BEFilterMap = filterKeyMap[type];

  const filtersMappedToDBFields: IFilter[] = filtersWithValues.map(
    filterObj => {
      const dBFieldNamesForFilter = BEFilterMap[filterObj['columnField']];

      return getFilterFromFieldNames(filterObj, dBFieldNamesForFilter);
    }
  );

  const convertedModel: { _and: IFilter[] } = {
    _and: filtersMappedToDBFields,
  };
  return convertedModel;
};

export const convertSortModelToBE = (sortModel, type) => {
  let convertedModel = [];
  for (let i = 0; i < sortModel.length; i++) {
    let totalMappings =
      type === 'PATIENT'
        ? plColToDBSortMapping[sortModel[i]['field']]
        : elColToDBSortMapping[sortModel[i]['field']];
    for (let j = 0; j < totalMappings.length; j++) {
      let model = Object.assign({}, sortModel[i]);
      model['field'] = totalMappings[j];
      model['columnField'] = model['field'];
      if (sortModel[i]['field'] === 'tviewer_due') {
        model['alternateSortProcessing'] = 'viewer';
      } else if (sortModel[i]['field'] === 'history') {
        model['alternateSortProcessing'] = 'report';
      } else if (sortModel[i]['field'] === 'tmct_ID') {
        model['nullSortProcessing'] = 'FIRST';
      }
      delete model['field']; // BE supports columnfield instead of field
      convertedModel.push(model);
    }
  }

  return convertedModel;
};

// Fills in any missing columns from the loaded view with columns from the default view
export const reconcileColumns = (new_columns, default_columns) => {
  // For each default column, find if there is a matching
  // column in the saved view we're loading
  const output_cols = default_columns.map(default_col => {
    const new_col = new_columns.find(
      new_col => new_col.header_name === default_col.header_name
    );

    // If we have a column in the saved view use that,
    // otherwise take the default column
    return new_col ? new_col : default_col;
  });

  return output_cols;
};
