import { Area, Bar, YAxis, Line, ComposedChart, XAxis } from 'recharts';

export function TrendChart(props) {
  return (
    <div
      id={props.id}
      style={{ display: 'inline-flex', alignContent: 'center' }}
    >
      {/* <ResponsiveContainer height={50} width={"100%"}> */}

      <ComposedChart
        height={40}
        width={420}
        data={props.data}
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 5,
        }}
      >
        <XAxis hide />
        <YAxis
          yAxisId={1}
          type='number'
          hide
          allowDataOverflow={true}
          domain={[props.min, props.max]}
        />
        <YAxis
          yAxisId={2}
          hide
          alignmentBaseline={'baseline'}
          type='number'
          domain={[props.minLine, props.maxLine]}
        />
        <YAxis yAxisId={3} type='number' hide domain={[0, 100]} />
        <Bar
          yAxisId={1}
          dataKey='bar'
          stackId='a'
          fill='#b4a0ff'
          stroke='#494949'
          barSize={10}
        />
        <Bar
          yAxisId={1}
          dataKey='night'
          stackId='a'
          fill='#b4a0ff'
          stroke='#494949'
          barSize={10}
        />
        <Bar
          yAxisId={1}
          dataKey='day'
          stackId='a'
          fill='white'
          stroke='#494949'
        />
        <Line
          yAxisId={2}
          connectNulls={true}
          type='monotone'
          dot={false}
          dataKey='line'
          stroke='#FF0000'
          strokeWidth={1}
        />
        <Area
          yAxisId={3}
          connectNulls={true}
          type='natural'
          dataKey='area'
          fill='#adaaaa'
          stroke='#adaaaa'
        />
      </ComposedChart>
    </div>
  );
}
