// External Libraries/components
import { useState, useEffect, useContext } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { isEmpty } from 'lodash';
import BSpinner from '../../common/BSpinner';

// Internal Libraries/components
import { commonData } from './../../../data/CommonData';
import BConfirm from '../../common/BConfirm';
import { generateID } from '../../../utility/utilityFunctions';
import { Grid, TextareaAutosize, Typography } from '@mui/material';
import ThresholdSettingsHeaderFooter from './ThresholdSettingsHeaderFooter';
import { ThresholdList } from './ThresholdList';
import BFormGroupTitle from '../../common/BFormGroupTitle';
import {
  getEventCriteriaValue,
  getInitialAndValidation,
  getValuesForMutation,
  thresholdSettingTemplateChange,
} from '../../../utility/helper/settings/SettingOperations';
import {
  SETTINGS,
  UPDATE_PATIENT_SETTINGS,
  THRESHOLD_PATIENT_SETTINGS,
  ADMIN_SETTINGS,
  RENAME_THRESHOLD_SETTINGS,
  SET_DEFAULT_NEW_PATIENT,
  DELETE_THRESHOLD_SETTINGS,
  UPDATE_ORG_SETTINGS,
  CREATE_ORG_SETTINGS,
  GET_ORG_ID,
} from '../../../data/queries/setting';
import { DEVICE_DATA } from '../../../data/queries/live';
import { useMutation, useQuery } from '@apollo/client';
import messageFormat from '../../../utility/messageToUser.json';
import {
  DEVICE_TYPE_MCT,
  THRESHOLD_SETTINGS_HEADER,
  IDropdownItem,
} from '../../../types/types';
import ThresholdSettingMain from './ThresholdSettingMain';
import OrgThresholdSettings from './OrgThresholdSettings';
import BCheckbox from '../../common/BCheckBox';
import { useLocation } from 'react-router-dom';
import { AccessPermissionsContext } from '../../../auth/useAccessPermissions';
import { useSendAuditTrailLogMessage } from '../../../data/queries/auditTrailLogMessage';

type IProps = {
  type: string;
  mctAdmin: boolean;
  gotoHome: () => void;
};

const PatientRegistrationSettings = ({ type, mctAdmin, gotoHome }: IProps) => {
  const location = useLocation(); // get this page's URL
  const patientId = location.pathname.split('/').pop(); // extract patientId
  const isAdminPage = patientId === 'admin'; // either are are adminSettings or patientSettings
  const PERMISSIONS = useContext(AccessPermissionsContext)?.permissions;

  // create parameter for fetching the organization threshold settings
  const getVariableForOrgThreshold = id => {
    var varOrgThreshold = [
      {
        fieldName: 'global_default',
        value: 'true',
      },
      {
        fieldName: 'organization_id',
        value: id,
      },
    ];
    return JSON.stringify(varOrgThreshold);
  };

  const getSortedSettingsByDisplayOrder = settingsArray => {
    let sortArray = [...settingsArray];
    let sortedThresholds = [];
    // sort by setting events
    sortArray.sort(
      (a, b) => a.settingType.display_order - b.settingType.display_order
    );

    // sort by thresholds within setting events
    sortedThresholds = sortArray.map(item => {
      let thresholds = [...item.thresholds]; // Assigning to thresholds and then perform sort on it since item is immutable
      thresholds.sort(
        (a, b) => a.thresholdType.display_order - b.thresholdType.display_order
      );
      return {
        ...item,
        thresholds: thresholds,
      };
    });
    return sortedThresholds;
  };

  // queries
  const [orgId, setOrgId] = useState(null);
  const {
    loading: loadingOrgId,
    error: orgIdError,
    data: orgIdData,
  } = useQuery(GET_ORG_ID);
  const {
    loading: deviceLoading,
    error: deviceError,
    data: deviceData,
  } = useQuery(DEVICE_DATA, {
    variables: { patientId: patientId },
    fetchPolicy: 'network-only', // do not cache
    skip: isAdminPage, // don't do this query if we are the admin page
  });

  const {
    loading: loadingDeviceSettings,
    error: errorDeviceSettings,
    data: dataDeviceSettings,
  } = useQuery(SETTINGS, {
    variables: {
      device_id: deviceData?.patient?.devices[0]?.id,
      organization_id: orgId,
      device_type: DEVICE_TYPE_MCT,
    },
    fetchPolicy: 'network-only',
    skip: !orgId || !deviceData,
  });

  const {
    loading: loadingOrgSettings,
    error: errorOrgSettings,
    data: dataOrgSettings,
    fetchMore: dataOrgFetchMore,
  } = useQuery(ADMIN_SETTINGS, {
    variables: {
      organization_value: getVariableForOrgThreshold(orgId),
      organization_id: orgId,
      device_type: DEVICE_TYPE_MCT,
    },
    fetchPolicy: 'network-only',
    skip: !orgId,
  });

  // mutations
  const [createSettings] = useMutation(UPDATE_PATIENT_SETTINGS);
  const [updateOrgSettings] = useMutation(UPDATE_ORG_SETTINGS);
  const [createOrgSettings] = useMutation(CREATE_ORG_SETTINGS);
  const [renameSettings] = useMutation(RENAME_THRESHOLD_SETTINGS);
  const [defaultForNewPatient] = useMutation(SET_DEFAULT_NEW_PATIENT);
  const [deleteSettings] = useMutation(DELETE_THRESHOLD_SETTINGS);
  const SETTINGS_HEADER = THRESHOLD_SETTINGS_HEADER;
  const { sendAuditMessage } = useSendAuditTrailLogMessage();

  // states
  const [savedChanges, setSavedChanges] = useState({
    apiCall: false,
    type: '',
    msg: '',
  });
  const [settingNotes, setSettingNotes] = useState('');
  const [settingUpdatesConfirm, setSettingUpdatesConfirm] = useState({
    open: false,
    data: [],
  });
  const [value, setValue] = useState({ settings: [] });
  const [thresholdList, setThresholdList] = useState([]);
  const [currentThresholdSelection, setCurrentThresholdSelection] =
    useState(null);
  const [systemDefaultId, setSystemDefault] = useState(null);
  const [initialValues, setInitialValues] = useState({});
  const [validationSchemaValues, setValidationSchemaValues] = useState({});
  const [settingNameMappings, setSettingNameMappings] = useState({});
  const [setTypeMap, setSetTypeMap] = useState({});
  const [thresTypeMap, setThresTypeMap] = useState({});
  const [unsaveConfirm, setUnsaveConfirm] = useState({ open: false });
  const [changedEvents, setChangedEvents] = useState({});
  const [pinnedNotes, setPinnedNotes] = useState(false); // By default, not selected as pinned notes

  useEffect(() => {
    if (orgIdData) {
      setOrgId(orgIdData?.organization?.id);
    }
  }, [orgIdData]);

  // Form the initial values for form once settings data is received
  useEffect(() => {
    if (!dataDeviceSettings) return; // if we are device page without data, return
    /**
     * Logic: Admin Threshold settings v/s Patient threshold settings
     * Admin: Setting belongs to the threshold and any selection from the threshold templates will be the teamplate itself, so entire setting object will be replaced
     * Patient: Setting belongs to the patient and any selection from the threshold template should just change the values of each Arrythmias/patient event
     */
    let settingValue: any = {
      settings: isAdminPage
        ? dataOrgSettings.settings[0].settings
        : dataDeviceSettings.settings,
    }; //getCombinedState({ states: [{ settings: data.settings, patient_id }], nestedKeysCreator });
    let sortedArray = getSortedSettingsByDisplayOrder(settingValue?.settings);
    settingValue.settings = [...sortedArray];
    setValue(settingValue);
    setThresholdList(
      getThresholdList(
        isAdminPage
          ? dataOrgSettings.settingGroups
          : dataDeviceSettings.settingGroups
      )
    );
    setFormValues(settingValue);
    if (dataDeviceSettings) {
      sendAuditMessage({ action: "DATA_ACCESS", phi_flag: true,
        description: `Loaded device settings: ${dataDeviceSettings.deviceId}` });
    }
    if (dataOrgSettings) {
      sendAuditMessage({ action: "DATA_ACCESS", phi_flag: true,
        description: `Loaded organization device settings: ${dataOrgSettings}` });
    }
  }, [dataOrgSettings, dataDeviceSettings]);

  useEffect(() => {
    if (currentThresholdSelection) {
      setFormValues(value);
    }
  }, [currentThresholdSelection]);

  // creates the structure for the initial values for form, validation object and mappings for confirming the setting changes
  const setFormValues = settingValue => {
    let dataValues = [];
    dataValues = getInitialAndValidation(settingValue, isAdminPage);
    setInitialValues(dataValues[0]);
    setValidationSchemaValues(dataValues[1]);
    setSettingNameMappings(dataValues[2]);
  };

  // create the threshold list for the threshold setting dropdown
  const getThresholdList = settingGroups => {
    let dropDownThreshold: IDropdownItem[] = [];
    for (let i = 0; i < settingGroups.length; i++) {
      dropDownThreshold.push({
        value: settingGroups[i].id, // threshold setting id
        label: settingGroups[i].name,
        isDefault: settingGroups[i].organization_default,
        grayed: settingGroups[i].global_default,
      });
      if (settingGroups[i].organization_default) {
        setCurrentThresholdSelection(settingGroups[i].id);
      }
      if (settingGroups[i].global_default) {
        setSystemDefault(settingGroups[i].id);
      }
    }
    return dropDownThreshold;
  };

  // handling the change in the threshold dropdown
  const thresholdSettingChange = (newThresholdValue, setFieldValue, values) => {
    if (isAdminPage === false) {
      // Threshold template changed in patient settings - replace values only
      // call the settings query with the newthresholdvalue
      dataOrgFetchMore({
        query: THRESHOLD_PATIENT_SETTINGS,
        variables: { device_id: newThresholdValue },
        updateQuery: (p, { fetchMoreResult }) => {
          if (fetchMoreResult.settings) {
            setChangedEvents({});
            let thresholdData = thresholdSettingTemplateChange(
              value.settings,
              initialValues,
              fetchMoreResult.settings,
              setFieldValue,
              setChangedEvents
            );
            let iniValData = thresholdData[1];
            setValue({ settings: Object.assign([], value.settings) });
            setInitialValues(Object.assign({}, iniValData));
            getUpdatedValues(values);
            setCurrentThresholdSelection(newThresholdValue);
            sendAuditMessage({ action: "DATA_ACCESS", phi_flag: true,
              description: `Retrieved device settings for ${newThresholdValue}` });
          }
        },
      });
    }
  };
  const getUpdatedValues = newValues => {
    // diff between new and old values only for the keywords starting from level and range in orgValues
    let msg = [];
    let orgValues = initialValues;
    for (const [key, value] of Object.entries(orgValues)) {
      if (!key.startsWith('range') && orgValues[key] != newValues[key]) {
        msg.push([
          settingNameMappings[key][0],
          orgValues[key],
          newValues[key],
          settingNameMappings[key][2] || null,
        ]);
      }
    }
    return msg;
  };
  const getChangedEventAbbr = () => {
    let msg = [];
    for (const [key, value] of Object.entries(changedEvents)) {
      msg.push(changedEvents[key]);
    }
    return msg.join(',');
  };
  const handleEventChange = (newValue, changed) => {
    let events = Object.assign({}, changedEvents);
    if (changed) {
      events[newValue] = newValue;
    } else {
      delete events[newValue];
    }
    setChangedEvents(events);
  };

  if (loadingOrgId)
    return <BSpinner text={'Loading Organization identifier...'} />;
  if (loadingOrgSettings)
    return <BSpinner text={'Loading Organization Settings...'} />;
  if (loadingDeviceSettings)
    return <BSpinner text={'Loading Device Settings...'} />;
  if (orgIdError) <p> Org Error occurred, please contact the Support Team! </p>;
  if (deviceError)
    <p> Device Error occurred, please contact the Support Team! </p>;

  return (
    <div style={{ width: `100%` }}>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={yup.object().shape(validationSchemaValues)}
        onSubmit={(values, actions) => {
          // 'initialValues' contains original values and 'values' contains updated values
          if (deviceData?.patient?.devices[0]?.id) {
            let apiData = {};
            apiData['patientId'] = patientId;
            apiData['deviceId'] = deviceData?.patient?.devices[0]?.id;
            apiData['settings'] = getValuesForMutation(values);
            apiData['notes'] = settingNotes;
            apiData['pinned'] = pinnedNotes;
            createSettings({ variables: apiData }).then(
              res => {
                setSavedChanges({
                  apiCall: true,
                  type: 'success',
                  msg: commonData.general.saveToastMessage,
                });
                setPinnedNotes(false); // reset pinned note checkbox
                console.log("Audit apiData", apiData);
                sendAuditMessage({ action: "RESOURCE_UPDATE", phi_flag: true,
                  description: `Saved device settings ${getUpdatedValues(values)}` });
              },
              error => {
                console.error(error);
                setSavedChanges({
                  apiCall: true,
                  type: 'error',
                  msg: commonData.general.saveErrorToastMessage,
                });
              }
            );
          } else {
            // org admin settings;
            let apiData = {};
            apiData['setting_group_id'] = currentThresholdSelection;
            apiData['settings'] = getValuesForMutation(values);
            apiData['notes'] = settingNotes;
            updateOrgSettings({ variables: apiData }).then(
              res => {
                setSavedChanges({
                  apiCall: true,
                  type: 'success',
                  msg: commonData.general.saveToastMessage,
                });
              },
              error => {
                console.error(error);
                setSavedChanges({
                  apiCall: true,
                  type: 'error',
                  msg: commonData.general.saveErrorToastMessage,
                });
              }
            );
          }
          actions.setSubmitting(false);
          actions.resetForm({ values: values });
          setInitialValues(values);
        }}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          dirty,
          touched,
          values,
          errors,
        }) => {
          return (
            <form>
              <BConfirm
                title={
                  messageFormat.patientRegistration.patientProfile
                    .confirmUnSavedChanges
                }
                open={unsaveConfirm.open}
                setOpen={setUnsaveConfirm}
                onConfirm={() => {
                  gotoHome();
                }}
                severity='warning'
                saveId={generateID('btn', 'pr_settings', 'discard_changes')}
                cancelId={generateID('btn', 'pr_settings', 'stay_on_page')}
              />
              <BConfirm
                title={
                  messageFormat.patientRegistration.patientSetting.verifyChanges
                }
                open={settingUpdatesConfirm.open}
                setOpen={setSettingUpdatesConfirm}
                onConfirm={() => {
                  setSettingUpdatesConfirm({ open: false, data: [] });
                  setChangedEvents({});
                  handleSubmit();
                }}
                cancelEvent={() => {
                  setSettingUpdatesConfirm({ open: false, data: [] });
                }}
                saveId={generateID('btn', 'pr_settings', 'confirm_changes')}
                cancelId={generateID('btn', 'pr_settings', 'cancel_changes')}
                okDisabled={settingNotes === ''}
              >
                <>
                  {settingUpdatesConfirm.data &&
                    settingUpdatesConfirm.data.map((dataItem, index) => {
                      let notification = dataItem[0];
                      let eventCriteriaName = dataItem[3];
                      let lhs = getEventCriteriaValue(
                        eventCriteriaName,
                        dataItem[1]
                      );
                      let rhs = getEventCriteriaValue(
                        eventCriteriaName,
                        dataItem[2]
                      );

                      return (
                        <div>
                          {notification} {eventCriteriaName} &nbsp;
                          {lhs} &#x2192; {rhs}{' '}
                        </div>
                      );
                    })}
                  <br />
                  {/* <BTextField shrink={true} label="Change Notes" value={settingNotes} name="setting_notes" handleChange={(e) => { setSettingNotes(e.target.value) }} /> */}
                  <TextareaAutosize
                    id={generateID('txtarea', 'pr_profile', 'notes')}
                    aria-label='minimum height'
                    style={{
                      minWidth: '300px',
                      fontFamily: [
                        'Nunito',
                        'Roboto',
                        '"Helvetica Neue"',
                        'Arial',
                        'sans-serif',
                      ].join(','),
                      fontSize: 16,
                    }}
                    required
                    minRows={3}
                    value={settingNotes}
                    name='setting_notes'
                    onChange={e => {
                      setSettingNotes(e.target.value);
                    }}
                  />

                  <div style={{ marginTop: '2px' }}></div>
                  {PERMISSIONS.includes('MCTTechnicianAccess') ? (
                    <>
                      <BCheckbox
                        name='setting_pin_notes'
                        id={generateID('chkbox', 'pr_setting', 'pinned_notes')}
                        style={{ paddingLeft: '0px' }}
                        checked={pinnedNotes}
                        handleChange={(_, value) => {
                          value ? setPinnedNotes(true) : setPinnedNotes(false);
                        }}
                      />
                      <Typography
                        style={{ display: 'inline' }}
                        id={
                          generateID('chkbox', 'pr_setting', 'pinned_notes') +
                          '-label'
                        }
                      >
                        Pin as a tech note for patient
                      </Typography>
                    </>
                  ) : null}
                </>
              </BConfirm>

              <Grid container>
                <Grid
                  container
                  spacing={2}
                  style={{ display: 'inline-flex', alignItems: 'center' }}
                >
                  <Grid item sm={12} lg={10}>
                    {/* components based on the type of settings */}
                    {type === 'orgAdmin' && (
                      <OrgThresholdSettings
                        isMCTAdmin={mctAdmin}
                        deviceType={DEVICE_TYPE_MCT}
                        setTypeMap={setTypeMap}
                        thresTypeMap={thresTypeMap}
                        ORGANIZATION_ID={orgId}
                        createOrgSettings={createOrgSettings}
                        renameSettings={renameSettings}
                        deleteSettings={deleteSettings}
                        defaultForNewPatient={defaultForNewPatient}
                        thresholdList={thresholdList}
                        thresholdSettingChange={thresholdSettingChange}
                        setFieldValue={setFieldValue}
                        values={Object.assign({}, values)}
                        currentThresholdSelection={currentThresholdSelection}
                        setCurrentThresholdSelection={
                          setCurrentThresholdSelection
                        }
                        systemDefaultId={systemDefaultId}
                        setSavedChanges={setSavedChanges}
                      />
                    )}
                  </Grid>
                  <Grid item sm={12} lg={2}>
                    <span
                      style={{
                        float: 'right',
                        display: 'inline-flex',
                        alignItems: 'center',
                      }}
                    >
                      <ThresholdSettingsHeaderFooter
                        style={{ marginTop: '94px', marginRight: '175px' }}
                        savedChanges={savedChanges}
                        setSavedChanges={setSavedChanges}
                        changedEvents={changedEvents}
                        getChangedEventAbbr={getChangedEventAbbr}
                        values={values}
                        dirty={dirty}
                        errors={errors}
                        setSettingUpdatesConfirm={setSettingUpdatesConfirm}
                        setUnsaveConfirm={setUnsaveConfirm}
                        getUpdatedValues={getUpdatedValues}
                        gotoHome={gotoHome}
                      />
                    </span>
                  </Grid>
                </Grid>
                {type && type === 'orgAdmin' ? (
                  <></>
                ) : (
                  <Grid container spacing={2} style={{ marginTop: '5px' }}>
                    <Grid item md={4} xs={12}>
                      <ThresholdList
                        id={generateID('drpdwn', 'pr_setting', 'threshold')}
                        name=''
                        label='Threshold Setting Name'
                        menuItems={thresholdList}
                        value={currentThresholdSelection}
                        shrink={true}
                        labelWidth={200}
                        fullWidth={true}
                        handleChange={event => {
                          // refetch the settings for selected threshold name
                          thresholdSettingChange(
                            event.target.value,
                            setFieldValue,
                            values
                          );
                        }}
                      />
                    </Grid>
                  </Grid>
                )}
                <Grid
                  container
                  spacing={1}
                  style={{
                    fontWeight: 'bolder',
                    marginTop: '5px',
                  }}
                  className='patient-list-title'
                >
                  {SETTINGS_HEADER.map(thresholdItem => {
                    return (
                      <Grid
                        item
                        md={thresholdItem.mdSize}
                        xs={thresholdItem.xsSize}
                        className={thresholdItem.className}
                      >
                        <BFormGroupTitle
                          title={thresholdItem.label}
                          icon={() => null}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
                <Grid container style={{ marginTop: '4px' }}>
                  <Grid item xs={12}>
                    {value.settings.length &&
                      value.settings.map((setting, rowValue) => (
                        <ThresholdSettingMain
                          isAdmin={isAdminPage}
                          mctAdmin={mctAdmin}
                          currentThresholdSelection={currentThresholdSelection}
                          key={`${setting.abbreviation} + ${rowValue}`}
                          handleEventChange={handleEventChange}
                          initialValues={initialValues}
                          {...setting}
                          {...{
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            dirty,
                            setFieldValue,
                            rowValue,
                          }}
                        />
                      ))}
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        style={{
                          display: 'inline-flex',
                          alignItems: 'center',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <ThresholdSettingsHeaderFooter
                          style={{ position: 'inherit', marginRight: '5px' }}
                          savedChanges={savedChanges}
                          setSavedChanges={setSavedChanges}
                          changedEvents={changedEvents}
                          getChangedEventAbbr={getChangedEventAbbr}
                          values={values}
                          dirty={dirty}
                          errors={errors}
                          setSettingUpdatesConfirm={setSettingUpdatesConfirm}
                          setUnsaveConfirm={setUnsaveConfirm}
                          getUpdatedValues={getUpdatedValues}
                          gotoHome={gotoHome}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default PatientRegistrationSettings;
