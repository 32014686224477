import { Grid } from '@mui/material';
import PatientEventNotification from './PatientEventNotification';

const BasePatientNotification = props => {
  return (
    <div
      id={props.id}
      style={{
        maxWidth: '720px',
        minWidth: '600px',
      }}
    >
      <Grid item xs={12}>
        <PatientEventNotification
          chartData={props.chartData}
          fetchTrendChartData={props.fetchTrendChartData}
          {...props}
        ></PatientEventNotification>
      </Grid>
    </div>
  );
};

export default BasePatientNotification;
