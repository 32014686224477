import { ApolloProvider } from '@apollo/client';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from '@azure/msal-react';
import { silentRequest } from './auth/msalConfig';
import { createApolloClient } from './data/Client';
import { useAcquireToken } from './auth/useAcquireToken';
import AfterAuth from './AfterAuth';
import AccessPermissionsProvider from './auth/useAccessPermissions';
import BSpinner from './components/common/BSpinner';

const AppWrapper = ({ config, profileId, token }) => {
  const { instance, accounts } = useMsal();
  if (!token) return <div>No Token Provided!</div>;

  const client = createApolloClient(
    config.apiEndpointUrl,
    token,
    { instance, accounts },
    config.NODE_ENV
  );
  return (
    <ApolloProvider client={client}>
      <AccessPermissionsProvider profileId={profileId} token={token}>
        <AfterAuth />
      </AccessPermissionsProvider>
    </ApolloProvider>
  );
};

const RedirectToLogin = () => {
  const { token, profileId, error } = useAcquireToken();
  const { instance } = useMsal();

  if (token) return <div>TOKEN</div>;
  instance.loginRedirect(silentRequest).catch(e => {
    console.error('Error attempting silent login:', e);
  });

  return <></>;
};

const App = ({ config }) => {
  const { token, profileId, loading } = useAcquireToken();
  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginRedirect(silentRequest).catch(e => {
      console.error('Error attempting silent login:', e);
    });
  };

  if (loading) return <BSpinner text={'Logging in...'} />;
  return (
    <div className='App'>
      <AuthenticatedTemplate>
        <AppWrapper config={config} token={token} profileId={profileId} />
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        {/* <h5 className='card-title'>
          Please <button onClick={handleLogin}>sign-in</button> to see your
          profile information.
        </h5> */}
        <RedirectToLogin />
      </UnauthenticatedTemplate>
    </div>
  );
};

export default App;
