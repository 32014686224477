import { useMutation } from '@apollo/client';
import { GET_REPORT_LINK } from '../../data/queries/report';
import { truncateToDecimal } from '../../utility/utilityFunctions';

export const EventAF = ({ eventData }) => {
  return (
    <>
      <tr>
        <td>
          Episodes: <b>{eventData.count}, </b>
          Max: <b>{eventData.maxBpm} bpm </b>
          Min: <b>{eventData.minBpm} bpm </b>
          Avg: <b>{truncateToDecimal(eventData.avgBpm, 0)} bpm </b>
        </td>
      </tr>
    </>
  );
};

export const EventAT = ({ eventData }) => {
  return (
    <>
      <tr>
        <td>
          Episodes: <b>{eventData.count} &nbsp;</b>
        </td>
        <td>
          <span>Longest: </span>
          <b>
            {truncateToDecimal(eventData.longestRun.duration, 1)}{' '}
            {eventData.longestRun.durationUnits}
          </b>{' '}
          @ Avg <b>{truncateToDecimal(eventData.longestRun.avgBpm, 0)} bpm</b>{' '}
          (up to <b>{truncateToDecimal(eventData.longestRun.maxBpm, 0)} bpm</b>)
        </td>
      </tr>
      <tr>
        <td></td>
        <td>
          Fastest: &nbsp;{' '}
          <b>
            {truncateToDecimal(eventData.fastestRun.duration, 1)}{' '}
            {eventData.fastestRun.durationUnits}
          </b>{' '}
          @ Avg <b>{truncateToDecimal(eventData.fastestRun.avgBpm, 0)} bpm</b>{' '}
          (up to <b>{truncateToDecimal(eventData.fastestRun.maxBpm, 0)} bpm</b>)
        </td>
      </tr>
    </>
  );
};

export const EventVT = ({ eventData }) => {
  return EventAT({ eventData });
};

export const EventPS = ({ eventData }) => {
  return (
    <table>
      <tr>
        <td>
          Episodes: <b>{eventData.count}</b>, Longest:{' '}
          <b>
            {truncateToDecimal(eventData.longestRun.duration, 1)}{' '}
            {eventData.longestRun.durationUnits}
          </b>
        </td>
      </tr>
    </table>
  );
};

export const EventSB = ({ eventData }) => {
  return (
    <table>
      <tr>
        <td>
          Episodes: <b>{eventData.count}, </b>
          Longest:{' '}
          <b>
            {truncateToDecimal(eventData.longestRun.duration, 1)}{' '}
            {eventData.longestRun.durationUnits}
          </b>
          &nbsp; Min: <b>{truncateToDecimal(eventData.minBpm, 0)} bpm</b>
        </td>
      </tr>
    </table>
  );
};

export const EventPVC = ({ eventData }) => {
  return (
    <table>
      <tr>
        <td>
          Burden: <b>{eventData.burden} %</b>
        </td>
      </tr>
    </table>
  );
};

export const EventBAT = ({ eventData }) => {
  return (
    <tr>
      <td>
        Charge: <b>{eventData.battPct}%</b>
      </td>
    </tr>
  );
};

export const EventTMP = ({ eventData }) => {
  return (
    <tr>
      <td>
        Avg: <b>{eventData.tempAvg}</b>
      </td>
    </tr>
  );
};

export const EventPE = ({ eventData }) => {
  return (
    <tr>
      <td>
        <b>{eventData.count}</b>
      </td>
    </tr>
  );
};

export const EventDT = ({ eventData }) => {
  return (
    <tr>
      <td>
        <b>{eventData.daysMissing}</b>
      </td>
    </tr>
  );
};

export const EventReportDue = ({ eventData }) => {
  return (
    <tr>
      <td>
        <b>{eventData.daysMissing}</b>
      </td>
    </tr>
  );
};

export const EventEventReport = ({ eventData }) => {
  const [getReportLink] = useMutation(GET_REPORT_LINK);
  let reportLink = eventData.reportLink;
  return (
    <tr>
      <td>
        <span
          style={{ cursor: 'pointer' }}
          onClick={e => {
            getReportLink({ variables: { reportFilename: reportLink } }).then(
              (data: any) => {
                let reportURL = data?.data?.getReportLink;
                window.open(reportURL, '_blank');
              }
            );
          }}
        >
          <b>Event Report Link</b>
        </span>
      </td>
    </tr>
  );
};

export const EventSummaryReport = ({ eventData }) => {
  const [getReportLink] = useMutation(GET_REPORT_LINK);
  let reportLink = eventData.reportLink;
  return (
    <tr>
      <td>
        <span
          style={{ cursor: 'pointer' }}
          onClick={e => {
            getReportLink({ variables: { reportFilename: reportLink } }).then(
              (data: any) => {
                let reportURL = data?.data?.getReportLink;
                window.open(reportURL, '_blank');
              }
            );
          }}
        >
          <b>Summary Report Link</b>
        </span>
      </td>
    </tr>
  );
};

export const selectEventComponent = (type, eventData) => {
  switch (type) {
    case 'T': {
      return <EventAT eventData={eventData}></EventAT>;
    }

    case 'AF': {
      return <EventAF eventData={eventData}></EventAF>;
    }

    case 'VT': {
      return <EventVT eventData={eventData}></EventVT>;
    }

    case 'P': {
      return <EventPS eventData={eventData}></EventPS>;
    }

    case 'B': {
      return <EventSB eventData={eventData}></EventSB>;
    }

    case 'PVC': {
      return <EventPVC eventData={eventData}></EventPVC>;
    }

    case 'BATTERY': {
      return <EventBAT eventData={eventData}></EventBAT>;
    }

    case 'TEMPERATURE': {
      return <EventTMP eventData={eventData}></EventTMP>;
    }

    case 'PATIENT_EVENTS': {
      return <EventPE eventData={eventData}></EventPE>;
    }

    case 'DATA_TRANSMISSION': {
      return <EventDT eventData={eventData}></EventDT>;
    }

    // Report status
    case 'EVENT_REPORT': {
      return <EventEventReport eventData={eventData}></EventEventReport>;
    }

    // Report status
    case 'SUMMARY_REPORT': {
      return <EventSummaryReport eventData={eventData}></EventSummaryReport>;
    }

    case 'BASELINE': {
      return <>Baseline Received</>;
    }

    default: {
      return <>NA</>;
    }
  }
};
