import { useState, ChangeEvent, useEffect, ReactNode } from 'react';
import { Tabs, Tab, Box, Typography, AppBar } from '@mui/material';
import { generateID } from './utility/utilityFunctions';
import {
  PATIENT_REGISTRATION_TABS,
  PATIENT_STEPS_TITLE,
  IPatientDetails,
} from './types/types';
import { useQuery, useMutation } from '@apollo/client';
import {
  C_GET_CURRENT_PATIENT,
  PATIENT_HEADER,
  SELECTED_PATIENT_ID,
  SELECT_PATIENT,
} from './data/queries/patient';
import BTabStepStatus from './components/common/BTabStepStatus';
import BIcon from './components/common/BIcon';
import { ICONS } from './utility/IconSelection';
import PatientRegistrationProfile from './components/patientregistration/profile/PatientRegistrationProfile';
import PatientRegistrationSettings from './components/patientregistration/settings/PatientRegistrationSettings';
import PatientRegistrationDevice from './components/patientregistration/device/PatientRegistrationDevice';
import PatientRegistrationHistory from './components/patientregistration/history/PatientRegistrationHistory';
import { useLocation, useNavigate } from 'react-router-dom';
import { Outlet } from 'react-router';
import PatientRegistrationHeader from './components/patientregistration/header/PatientRegistrationHeader';

interface TabPanelProps {
  children?: ReactNode;
  dir?: string;
  index: any;
  value: any;
}

const TabPanel = ({ children, value, index, ...props }: TabPanelProps) => (
  <div
    role='tabpanel'
    hidden={value !== index}
    id={generateID(
      'win',
      'prwin_tabpanel',
      PATIENT_REGISTRATION_TABS[index]['id']
    )}
    aria-labelledby={generateID(
      'win',
      'prwin_tab',
      PATIENT_REGISTRATION_TABS[index]['id']
    )}
    {...props}
  >
    {value === index && (
      <Box p={4}>
        <Typography>{children}</Typography>
      </Box>
    )}
  </div>
);

const getPatientRegistrationTabAttrs = (index: number) => ({
  id: generateID('win', 'prwin_tab', PATIENT_REGISTRATION_TABS[index]['id']),
  'aria-controls': generateID(
    'win',
    'prwin_tabpanel',
    PATIENT_REGISTRATION_TABS[index]['id']
  ),
});

const PatientRegistrationComponent = () => {
  const { pathname, state } = useLocation();
  const location = state; // TODO rename this probably?
  const pathnameSplit = pathname.split(/[/&]/).filter(part => part !== '');

  const navigate = useNavigate();
  const gotoHome = () => {
    navigate('/events');
  };

  const selectedPatientId = pathnameSplit[1];

  let {
    loading: loadingPatientDetails,
    error: errorPatientDetails,
    data: patientDetails,
    refetch,
  } = useQuery(PATIENT_HEADER, {
    variables: { patient_id: selectedPatientId },
    fetchPolicy: 'network-only',
    skip: !selectedPatientId,
  });

  const [setPatient] = useMutation(SELECT_PATIENT);
  useEffect(() => {
    // grab selectedPatientId from path
    // TODO check if current is logged in and has permission to view this path
    const pathname = pathnameSplit[pathnameSplit.length - 1]; // patientId then deviceId
    setPatient({ variables: { id: pathname === 'new' ? null : pathname } });
  }, []);

  const [activeTab, setActiveTab] = useState(0);
  useEffect(() => {
    if (location?.tab)
      // on load what tab to default to
      setActiveTab(Number(PATIENT_STEPS_TITLE[location?.tab]));
  }, []);

  const handleTabChange = (event: ChangeEvent<{}>, newValue: number) => {
    setActiveTab(newValue);
  };

  const TabLabel = ({ icon, text }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <BTabStepStatus completed={selectedPatientId} />
      <BIcon
        style={{ verticalAlign: 'middle', fontSize: '2rem' }}
        icon={icon}
      />{' '}
      {text}
    </div>
  );

  // TODO implement this redirect, including auth once users are available
  if (patientDetails?.patient === null) gotoHome(); // if an invalid selectedPatientId is in URL
  const reflectUpdatedPatientChanges = () => {
    refetch();
  };
  return (
    <div>
      <AppBar
        position='relative'
        color='default'
        sx={{
          backgroundColor: 'white',
        }}
      >
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          textColor='primary'
          variant='fullWidth'
          TabIndicatorProps={{
            style: {
              background: 'primary',
              height: '6px',
              fontWeight: 'bolder',
            },
          }}
        >
          {/* Tabs */}
          <Tab
            label={<TabLabel icon={ICONS['INFORMATION']} text='Profile' />}
            {...getPatientRegistrationTabAttrs(0)}
            id={generateID(
              'win',
              'prwin_tab',
              PATIENT_REGISTRATION_TABS[0]['id']
            )}
            aria-controls={generateID(
              'win',
              'prwin_tabpanel',
              PATIENT_REGISTRATION_TABS[0]['id']
            )}
          />
          <Tab
            disabled={!selectedPatientId}
            label={<TabLabel icon={ICONS['SETTINGS']} text={'Settings'} />}
            id={generateID(
              'win',
              'prwin_tab',
              PATIENT_REGISTRATION_TABS[1]['id']
            )}
            aria-controls={generateID(
              'win',
              'prwin_tabpanel',
              PATIENT_REGISTRATION_TABS[1]['id']
            )}
          />
          <Tab
            disabled={!selectedPatientId}
            id={generateID(
              'win',
              'prwin_tab',
              PATIENT_REGISTRATION_TABS[2]['id']
            )}
            aria-controls={generateID(
              'win',
              'prwin_tabpanel',
              PATIENT_REGISTRATION_TABS[2]['id']
            )}
            label={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <BTabStepStatus
                  // TODO clean this up and pass in boolean
                  completed={patientDetails?.patient?.devices?.some(
                    d => d.active
                  )}
                  titleIncompleteStep='Device not registered'
                />
                <BIcon
                  style={{ verticalAlign: 'middle', fontSize: '2rem' }}
                  icon={ICONS['Device']}
                />{' '}
                Device
              </div>
            }
          />
          <Tab
            disabled={!selectedPatientId}
            id={generateID(
              'win',
              'prwin_tab',
              PATIENT_REGISTRATION_TABS[3]['id']
            )}
            aria-controls={generateID(
              'win',
              'prwin_tabpanel',
              PATIENT_REGISTRATION_TABS[3]['id']
            )}
            label={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <BTabStepStatus
                  // TODO clean this up and pass in boolean
                  completed={patientDetails?.patient?.devices?.some(
                    d => d.active
                  )}
                />
                <BIcon
                  style={{ verticalAlign: 'middle' }}
                  icon={ICONS['REPORTS']}
                />{' '}
                History
              </div>
            }
          />
        </Tabs>
      </AppBar>
      <PatientRegistrationHeader patientDetails={patientDetails} />
      {/* Tab Content */}
      <TabPanel value={activeTab} index={0}>
        <PatientRegistrationProfile
          {...location}
          type={location?.type}
          patientDetails={patientDetails}
          reflectUpdatedPatientChanges={() => {
            reflectUpdatedPatientChanges();
          }}
          gotoHome={gotoHome}
        />
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        <PatientRegistrationSettings
          gotoHome={gotoHome}
          type=''
          mctAdmin={false}
        />
      </TabPanel>
      <TabPanel value={activeTab} index={2}>
        <PatientRegistrationDevice {...location} gotoHome={gotoHome} />
      </TabPanel>
      <TabPanel value={activeTab} index={3}>
        <PatientRegistrationHistory
          patientDetails={patientDetails}
          params={location?.params}
          gotoHome={gotoHome}
          reflectUpdatedPatientChanges={() => {
            reflectUpdatedPatientChanges();
          }}
        />
      </TabPanel>
      <Outlet />
    </div>
  );
};

export default PatientRegistrationComponent;
