import { useState, useEffect, useRef, memo } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import {
  GET_REPORT_LINK,
  GET_REPORT_DATA,
  APPROVE_REPORT,
  PENDING_REPORT_REQUESTS,
} from '../../data/queries/report';
import BButton from '../common/BButton';
import BIcon from '../common/BIcon';
import BConfirm from '../common/BConfirm';
import { Formik } from 'formik';
import { ICONS } from '../../utility/IconSelection';
import BTextField from '../common/BTextField';
import BDialog from '../common/BDialog';
import BDropDown from '../common/BDropDown';
import { Stack } from '@mui/material';
import { generateID } from '../../utility/utilityFunctions';
import { REASON_FOR_CHANGE_OPTIONS } from '../../types/types';
import {
  INITIAL_REPORT_APPROVAL_VALIDATION_SCHEMA,
  EDIT_REPORT_APPROVAL_VALIDATION_SCHEMA,
} from '../../utility/validationSchema';
import { useSendAuditTrailLogMessage } from '../../data/queries/auditTrailLogMessage';

const ObjectPDF = ({ pdfURL }) => {
  // if (!pdfURL) return null;
  return (
    <object
      style={{ height: '100vh' }}
      data={pdfURL}
      type='application/pdf'
      width='100%'
    >
      PDF cannot be rendered in this browser
    </object>
  );
};

const CanView = ({ children, show }) => {
  if (!show) return;
  return children;
};
const ChangeAnnotationsModal = ({
  handleConfirm,
  handleCancel,
  show,
  approvedAt,
  approver,
}) => {
  const dateString = new Date(parseInt(approvedAt)).toLocaleString();
  if (!show) {
    handleConfirm();
    return;
  }
  return (
    <BConfirm
      title={'Change the annotations?'}
      open={show}
      setOpen={() => {}}
      onConfirm={handleConfirm}
      cancelEvent={handleCancel}
      okCaption={'Yes'}
      cancelCaption={'No'}
    >
      <p>
        The annotations have been approved by{' '}
        <b>
          {approver?.first_name}&nbsp;{approver?.last_name}
        </b>
        , signed {dateString}.
      </p>
      <p>Do you wish to change the annotations for this report?</p>
    </BConfirm>
  );
};

const Attestation = () => (
  <p>
    I attest that the following statements are accurate to the best of my
    knowledge.
  </p>
);

const Aside = ({
  reportURL,
  reportData,
  handleApproveDownload,
  handleApproveClose,
  isEditMode,
  hasPrevApproval,
}) => {
  const asideStyles = {
    display: 'flex',
    flexDirection: 'column' as 'column',
    width: '500px',
  };

  const infoStyles = {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '12px',
  };

  const [interpretationText, setInterpretationText] = useState('');
  const deviceId = reportData?.report.device.deviceId || '';
  const { sendAuditMessage } = useSendAuditTrailLogMessage();

  useEffect(() => {
    if (reportData?.report.provider_interpretation?.length) {
      setInterpretationText(reportData?.report.provider_interpretation);
    } else {
      setInterpretationText('');
    }
  }, [reportData]);

  if (!reportData?.report.device.patients[0]) return <p>Loading...</p>;

  const {
    first_name,
    last_name,
    id,
    prescribing_provider,
    patient_identifier,
  } = reportData?.report.device.patients[0];

  const patientLink = `/patient/${id}`;
  const fullName = `${last_name}, ${first_name}`;

  const patientData = {
    patientLink,
    fullName,
    prescribingProvider: prescribing_provider || 'N/A',
    patientIdentifier: patient_identifier,
  };

  return (
    <aside style={asideStyles}>
      <div>EOS Summary Report</div>
      <div id='patient-info'>
        <div id='patient-name' className='patient-info' style={infoStyles}>
          <p>Patient Name:</p>
          <Link
            to={patientData.patientLink}
            style={{ textUnderlineOffset: '5px' }}
          >
            <p>
              {patientData.fullName}
              <BIcon
                icon={ICONS['EDIT']}
                style={{
                  width: '16px',
                  height: '16px',
                  marginLeft: '5px',
                }}
              />
            </p>
          </Link>
        </div>
        <div id='patient-id' className='patient-info' style={infoStyles}>
          <p>Patient ID/MRN:</p>
          <p>{patientData.patientIdentifier}</p>
        </div>
        <div id='device-sn' className='patient-info' style={infoStyles}>
          <p>Device SN:</p>
          <p>{deviceId}</p>
        </div>
      </div>
      <div
        id='prescribing-provider'
        className='patient-info'
        style={infoStyles}
      >
        <p>Prescribing provider:</p>
        <p>{patientData.prescribingProvider}</p>
      </div>
      <Formik
        initialValues={{
          interpretation_text: interpretationText,
          reason_for_change: 'Initially Omitted', // TODO revert this to empty string when finished testing
        }}
        validationSchema={
          hasPrevApproval
            ? EDIT_REPORT_APPROVAL_VALIDATION_SCHEMA
            : INITIAL_REPORT_APPROVAL_VALIDATION_SCHEMA
        }
        onSubmit={(values, { resetForm }) => {}}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          submitForm,
          setFieldValue,
          setStatus,
          validateForm,
          touched,
        }) => (
          <form
            autoComplete='off'
            noValidate
            onSubmit={e => {
              e.preventDefault();
            }}
          >
            <div
              id='provider-interpretation'
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                marginBottom: '25px',
              }}
            >
              <label>Provider Interpretation</label>
              <BTextField
                multiline={true}
                name='interpretation_text'
                label=''
                id={generateID(
                  'textarea',
                  'report_viewer',
                  'interpretation_text'
                )}
                value={interpretationText}
                rows={10}
                handleChange={e => {
                  setInterpretationText(e.target.value);
                  setFieldValue('interpretation_text', e.target.value);
                }}
                shrink={false}
                disabled={!isEditMode}
              />
            </div>
            <CanView show={isEditMode && hasPrevApproval}>
              <BDropDown
                id={generateID('drpdwn', 'report_viewer', 'reason_for_change')}
                label='Reason for change'
                menuItems={REASON_FOR_CHANGE_OPTIONS}
                errors={errors.reason_for_change}
                shrink={true}
                labelWidth={55}
                fullWidth={true}
                required={true}
                value={values.reason_for_change}
                handleChange={e => {
                  handleChange(e);
                  setFieldValue('reason_for_change', e.target.value);
                }}
              />
            </CanView>
            <CanView show={isEditMode && !hasPrevApproval}>
              <div style={{ visibility: 'hidden' }}>
                <BDropDown
                  id={generateID(
                    'drpdwn',
                    'report_viewer',
                    'reason_for_change'
                  )}
                  label='Reason for change'
                  menuItems={[]}
                  errors={errors.reason_for_change}
                  shrink={true}
                  labelWidth={55}
                  fullWidth={true}
                  required={true}
                  value={'Initially Omitted'} // TODO change to placeholder 'first report' value
                />
              </div>
            </CanView>
            <div>
              <Stack spacing={2} direction='column'>
                <CanView show={isEditMode}>
                  <Attestation />
                  <BButton
                    id={generateID('btn', 'report_viewer', 'approve_download')}
                    style={{ width: '100%' }}
                    caption={'APPROVE & DOWNLOAD PDF'}
                    clickEvent={() => {
                      handleApproveDownload({ values, errors });
                    }}
                    submit={'button'}
                  />
                  <BButton
                    id={generateID('btn', 'report_viewer', 'approve_close')}
                    style={{ width: '100%' }}
                    caption={'APPROVE & CLOSE'}
                    clickEvent={() => {
                      handleApproveClose({ values, errors });
                    }}
                    submit={'button'}
                  />
                </CanView>
                <a
                  id={generateID('btn', 'report_viewer', 'download')}
                  href={reportURL}
                  target='_blank'
                  download={'report.pdf'}
                >
                  <BButton
                    id={generateID('btn', 'report_viewer', 'download_pdf')}
                    style={{ width: '100%' }}
                    caption={'DOWNLOAD PDF'}
                    color={'secondary'}
                    clickEvent={() => {
                      sendAuditMessage({
                        action: 'DATA_ACCESS',
                        phi_flag: true,
                        description: `Downloaded report ${reportURL} for device ${reportData?.report?.device?.deviceId}`,
                      });
                    }}
                    submit={'button'}
                  />
                </a>
              </Stack>
            </div>
          </form>
        )}
      </Formik>
    </aside>
  );
};

const ReportsViewerWrapper = () => {
  let { id: reportId } = useParams();
  const navigate = useNavigate();
  if (!reportId) navigate('/events');

  let {
    loading: loadingReportData,
    error: errorReportData,
    data: reportData,
  } = useQuery(GET_REPORT_DATA, {
    variables: { reportId: reportId },
    fetchPolicy: 'network-only',
    skip: !reportId,
  });

  const [getReportLink] = useMutation(GET_REPORT_LINK);
  const [reportURL, setReportURL] = useState<string | null>(null);

  useEffect(() => {
    // TODO check if current is logged in and has permission to view this path

    const filePath = reportData?.report?.file_link || '';

    if (!reportData) return;
    if (!filePath) return;
    getReportLink({
      variables: { reportFilename: filePath },
    })
      .then((data: any) => {
        let reportURL = data?.data?.getReportLink;
        setReportURL(reportURL);
      })
      .catch(err => {
        console.error('ERROR:', err);
      });
  }, [reportData, getReportLink]);

  return (
    <ReportsViewer
      reportData={reportData}
      loading={loadingReportData}
      reportURL={reportURL}
    >
      <ObjectPDF pdfURL={reportURL} />
    </ReportsViewer>
  );
};

const ReportsViewer = ({ reportData, reportURL, loading, children }) => {
  let { id: reportId } = useParams();
  const [isEditMode, setIsEditMode] = useState<boolean | null>(null);
  const [showErrorModal, setShowErrorModal] = useState<boolean | null>(false);
  const [isApprovalPending, setIsApprovalPending] = useState<boolean>(false);
  const [isApproveDownloadPending, setIsApproveDownloadPending] =
    useState<boolean>(false);

  const [approveReport] = useMutation(APPROVE_REPORT);
  const {
    data: pendingReportRequestsData,
    loading: pendingReportRequestsLoading,
    error: pendingReportRequestsError,
  } = useQuery(PENDING_REPORT_REQUESTS, {
    variables: {
      reportId: reportId,
    },
  });

  useEffect(() => {
    if (pendingReportRequestsData?.reportRequests.length > 0) {
      setIsApprovalPending(true);
    } else {
      setIsApprovalPending(false);
    }
  }, [pendingReportRequestsData]);

  const [approvalData, setApprovalData] = useState({
    approval_status: null,
    approved_at: null,
    approved_by: null,
    approver: null,
  });

  const { sendAuditMessage } = useSendAuditTrailLogMessage();

  useEffect(() => {
    if (!reportData) return;
    const newApprovalData = {
      approval_status: reportData.report.approval_status,
      approved_by: reportData.report.approved_by,
      approved_at: reportData.report.approved_at,
      approver: reportData.report.approver,
    };

    setApprovalData(newApprovalData);

    sendAuditMessage({
      action: 'DATA_ACCESS',
      phi_flag: true,
      description: `Viewed report ${reportId} for device ${reportData?.report?.device?.deviceId}`,
    });
  }, [reportData]);

  const wrapperStyles = {
    display: 'flex',
  };

  const dialogStyle = {
    width: '480px',
    height: '237px',
    flexShrink: '0',
    borderRadius: '16px',
    background: 'var(--color-neutrals-00, #FFF)',
    boxShadow:
      '0px 10px 18px 0px rgba(8, 27, 57, 0.15), 0px 0px 1px 0px rgba(8, 27, 57, 0.31)',
  };

  const setEditOnlyMode = () => setIsEditMode(true);
  const setViewOnlyMode = () => setIsEditMode(false);
  const handleErrorConfirm = () => setShowErrorModal(false);
  const closeWindow = () => {
    window.opener = null;
    window.open('', '_self');
    window.close();
  };
  const handleApproveClose = async ({ values, errors }) => {
    if (Object.keys(errors).length) return;
    setIsApprovalPending(true);
    try {
      const response = await approveReport({
        variables: {
          reportId: reportId,
          deviceId: reportData?.report?.device?.deviceId,
          interpretationText: values.interpretation_text,
          reportType: 'eos', // reportData?.report?.reportType.name,
          reasonForChange: values.reason_for_change,
        },
      });

      if (response.errors) {
        throw new Error(response.errors[0].message);
      }

      sendAuditMessage({
        action: 'RESOURCE_UPDATE',
        phi_flag: true,
        description: `Approved report ${reportId} for device ${reportData?.report?.device?.deviceId}`,
      });
      closeWindow();
    } catch (error) {
      console.error('Error approving report:', error);
      setIsApprovalPending(false);
      setShowErrorModal(true);
    }
  };
  const handleApproveDownload = async ({ values, errors }) => {
    if (Object.keys(errors).length) return;
    setIsApproveDownloadPending(true);

    const response = await approveReport({
      variables: {
        reportId: reportId,
        deviceId: reportData?.report?.device?.deviceId,
        interpretationText: values.interpretation_text,
        reportType: 'eos', // reportData?.report?.reportType.name,
        reasonForChange: values.reason_for_change,
      },
    });

    if (response.errors) {
      throw new Error(response.errors[0].message);
    }

    sendAuditMessage({
      action: 'RESOURCE_UPDATE',
      phi_flag: true,
      description: `Approved and downloaded report ${reportId} for device ${reportData?.report?.device?.deviceId}`,
    });
  };

  const ReportGenerationErrorModal = ({ handleErrorConfirm, show }) => (
    <BConfirm
      title={'REPORT UPLOAD FAILED'}
      open={show}
      setOpen={() => {}}
      onConfirm={handleErrorConfirm}
      okCaption={'Ok'}
      showCancelButton={false}
      saveId='btn__report__failed__modal__confirm'
    >
      <p>
        Report upload failed. Your provider interpretation may not have been
        saved.
      </p>
      <p>
        Please check your interpretation in the report and re-approve this
        report.
      </p>
    </BConfirm>
  );

  // TODO ajk: show after handleApproveDownload()
  const InProgressModal = ({ show }) => (
    <BDialog
      open={show}
      setOpen={() => {}}
      style={dialogStyle}
      message='Report approval in progress, this may take a few seconds'
    ></BDialog>
  );

  // TODO ajk: show when hitting page while we are still in progress
  const ReportGeneratingModal = ({ show }) => (
    <BDialog
      open={show}
      setOpen={() => {}}
      style={dialogStyle}
      message='Please wait while report is being generated'
    ></BDialog>
  );

  return (
    <div style={wrapperStyles}>
      <ChangeAnnotationsModal
        handleConfirm={setEditOnlyMode}
        handleCancel={setViewOnlyMode}
        show={
          approvalData.approval_status === 'approved' &&
          isEditMode === null &&
          approvalData.approver !== null
        }
        approvedAt={approvalData.approved_at}
        approver={approvalData.approver}
      />
      <ReportGenerationErrorModal
        handleErrorConfirm={handleErrorConfirm}
        show={showErrorModal}
      />

      <InProgressModal show={isApprovalPending} />
      <ReportGeneratingModal show={isApproveDownloadPending} />
      {loading || (
        <>
          {children}
          <Aside
            isEditMode={isEditMode}
            reportURL={reportURL}
            reportData={reportData}
            handleApproveDownload={handleApproveDownload}
            handleApproveClose={handleApproveClose}
            hasPrevApproval={reportData?.report?.approval_status === 'approved'}
          />
        </>
      )}
    </div>
  );
};

export default ReportsViewerWrapper;
