// External Libraries
import React, { useState } from 'react';
import {
  Box,
  Tab,
  Tabs,
  AppBar,
  Paper,
  Table,
  TableBody,
  TableContainer,
  Avatar,
  Grid,
  Typography,
  TableCell,
} from '@mui/material';

// Internal Libraries
import {
  generateID,
  getEventIconStyles,
  getEventStyles,
  getPrettyDate,
} from './../../utility/utilityFunctions';
import { formECGViewerURL } from './../../utility/helper/patientlist/logicPatientList';
import BIcon from './../common/BIcon';
import BSaveCancel from './../common/BSaveCancel';
import BStyledTableRow from './../common/BStyledTableRow';
import { DF_SDSDSD_C_DD_MMM_YYYY_SHSH_COL_SMSM_COL_SSSS_SA } from './../../types/types';
import { TrendChart } from '../charts/TrendChart';
import { selectEventComponent } from './EventGroup';
import Config, { IConfig } from './../../types/Config';
import { ICONS } from '../../utility/IconSelection';
import BButton from '../common/BButton';
import { PLDeviceEventGroupStatus } from '../../utility/helper/patientlist/plcomponents';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

import { CloseRounded } from '@mui/icons-material';
import OrgContactInformation from '../organization/OrgContactInformation';
import { AccessPermissionsContext } from '../../auth/useAccessPermissions';

function TabPanel(props) {
  const { children, value, index, type, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={generateID('win', 'esumwin_tabpanel', TREND_CHART_TABS[index]['id'])}
      aria-labelledby={generateID(
        'win',
        'esumwin_tab',
        TREND_CHART_TABS[index]['id']
      )}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const TREND_CHART_TABS = {
  0: {
    id: 'monthly',
    label: 'MONTHLY',
  },
  1: {
    id: 'one_yearly',
    label: 'YEARLY',
  },
  2: {
    id: 'three_yearly',
    label: '3 YEARLY',
  },
};
const PatientEventNotification = props => {
  const endDate = props.endTime;
  const strDate = props.startTime;
  const [value, setValue] = useState(0);
  const [toggleOrgContact, setToggleOrgContact] = useState(false);
  const config = React.useContext<IConfig>(Config);
  const eventThresholdObj = props.eventThresholdObj || {};
  const USER_ACCESS = React.useContext(AccessPermissionsContext)?.access;
  const { canAccessTechNotes } = USER_ACCESS;

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
    // fetch the trend chart for selected duration
    props.fetchTrendChartData(newValue, props.setChartData);
  };
  function getTrendChartDuration(index: any) {
    return {
      id: generateID('win', 'esumwin_tab', TREND_CHART_TABS[index]['id']),
      'aria-controls': generateID(
        'win',
        'esumwin_tabpanel',
        TREND_CHART_TABS[index]['id']
      ),
    };
  }

  return (
    <>
      <Grid container>
        <Grid item xs={props.chartData ? 7 : 12}>
          <Grid container className='notification-header'>
            <Grid item xs={11} style={{ padding: '10px' }}>
              <Typography>
                {props.lastName}
                {', ' + props.firstName}&nbsp;{' '}
                {getPrettyDate(
                  endDate,
                  DF_SDSDSD_C_DD_MMM_YYYY_SHSH_COL_SMSM_COL_SSSS_SA,
                  true
                )}
              </Typography>
            </Grid>
            <Grid
              item
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'right',
                paddingRight: '10px',
              }}
              xs={1}
              className='notification-close-parent'
            >
              <CloseRounded
                id={generateID(
                  'icon',
                  'esumwin',
                  `close_${props.deviceEventId}`
                )}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  props.clickHandler();
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          style={{ display: props.chartData ? 'inline-block' : 'none' }}
          xs={5}
        >
          <AppBar position='relative'>
            <Tabs
              value={value}
              scrollButtons={false}
              onChange={handleChange}
              aria-label='simple tabs example'
              // className={classes.indicator}
              TabIndicatorProps={{ style: { display: 'none' } }}
            >
              <Tab label='Monthly' {...getTrendChartDuration(0)} />
              <Tab label='Yearly' {...getTrendChartDuration(1)} />
              <Tab label='3 Yearly' {...getTrendChartDuration(2)} />
            </Tabs>
          </AppBar>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid
              className='notification-subheader'
              item
              xs={12}
              style={{ padding: '10px' }}
            >
              <Typography>
                {props.age + ':' + props.sex[0].toUpperCase()}{' '}
                {props.indications}
                <span
                  onClick={() => {
                    setToggleOrgContact(prev => !prev);
                  }}
                  className='org-contact-link'
                >
                  Clinical Contact
                  {toggleOrgContact ? (
                    <KeyboardArrowUp style={{ verticalAlign: 'middle' }} />
                  ) : (
                    <KeyboardArrowDown style={{ verticalAlign: 'middle' }} />
                  )}
                </span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {toggleOrgContact ? (
                <Grid container>
                  <Grid item>
                    <OrgContactInformation
                      sideBySide={true}
                      {...props.orgContactDetails}
                    />
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
            {canAccessTechNotes ? (
              <Grid
                item
                xs={12}
                style={{
                  padding: '10px',
                }}
              >
                <Typography>
                  {/* TODO: Get icon from UX Team */}
                  <BIcon
                    style={{ verticalAlign: 'middle' }}
                    icon={ICONS['NOTES']}
                  />{' '}
                  {props.technicianNotes}
                </Typography>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table size='small'>
              <colgroup>
                <col width={props.chartData ? '3%' : '10%'} />
                <col width={props.chartData ? '35%' : '60%'} />
                <col width={props.chartData ? '20%' : '25%'} />
                {props.chartData ? (
                  <col width={props.chartData ? '40%' : '0'} />
                ) : null}
              </colgroup>
              <TableBody>
                <tr style={{ background: 'lightgray' }}>
                  <TableCell align='left'></TableCell>
                  <TableCell align='left'>Notification</TableCell>
                  <TableCell align='left'>Event Criteria</TableCell>
                </tr>
                {props.eventList.map((row, index) => {
                  return (
                    <BStyledTableRow>
                      <TableCell align='left'>
                        {row.type !== 'string' ? (
                          <BIcon
                            icon={props.icons[row.type]}
                            fontSize='large'
                            style={Object.assign(
                              {
                                display: 'flex',
                                justifyContent: 'center',
                                fontSize: '2.0rem',
                              },
                              getEventIconStyles(row.severity)
                            )}
                          />
                        ) : (
                          <>
                            {
                              <Avatar
                                variant='rounded'
                                sx={getEventStyles('events', row.severity)}
                              >
                                {row.abbr}
                              </Avatar>
                            }
                          </>
                        )}
                      </TableCell>

                      <TableCell align='left'>
                        {row.severity == 'off' ? (
                          <>{row.value}</>
                        ) : (
                          <div>{selectEventComponent(row.abbr, row.value)}</div>
                        )}
                      </TableCell>
                      <TableCell>
                        {eventThresholdObj[row.abbr]?.map(item => {
                          return (
                            <div>
                              {item.deviceEventSettingThresholdType.name}{' '}
                              {item.value}
                            </div>
                          );
                        })}
                      </TableCell>

                      {props.chartData ? (
                        <TableCell>
                          {/* Space for trendchart */}
                          <div
                            style={{ width: '97%', display: 'inline-block' }}
                          >
                            <TabPanel
                              value={value}
                              index={0}
                              // className={classes.tab}
                              type={row.abbr}
                            >
                              <TrendChart
                                id={generateID(
                                  'win',
                                  'esumwin_tabpanel',
                                  TREND_CHART_TABS[0]['id'] + '_' + row.abbr
                                )}
                                data={props.chartData[row.abbr]['values']}
                                min={props.chartData[row.abbr]['min']}
                                max={props.chartData[row.abbr]['max']}
                                minLine={props.chartData[row.abbr]['minLine']}
                                maxLine={props.chartData[row.abbr]['maxLine']}
                              ></TrendChart>
                            </TabPanel>
                            <TabPanel
                              value={value}
                              index={1}
                              // className={classes.tab}
                              type={row.abbr}
                            >
                              <TrendChart
                                id={generateID(
                                  'win',
                                  'esumwin_tabpanel',
                                  TREND_CHART_TABS[1]['id'] + '_' + row.abbr
                                )}
                                data={props.chartData[row.abbr]['values']}
                                min={props.chartData[row.abbr]['min']}
                                max={props.chartData[row.abbr]['max']}
                                minLine={props.chartData[row.abbr]['minLine']}
                                maxLine={props.chartData[row.abbr]['maxLine']}
                              ></TrendChart>
                            </TabPanel>
                            <TabPanel
                              value={value}
                              index={2}
                              // className={classes.tab}
                              type={row.abbr}
                            >
                              <TrendChart
                                id={generateID(
                                  'win',
                                  'esumwin_tabpanel',
                                  TREND_CHART_TABS[2]['id'] + '_' + row.abbr
                                )}
                                data={props.chartData[row.abbr]['values']}
                                min={props.chartData[row.abbr]['min']}
                                max={props.chartData[row.abbr]['max']}
                                minLine={props.chartData[row.abbr]['minLine']}
                                maxLine={props.chartData[row.abbr]['maxLine']}
                              ></TrendChart>
                            </TabPanel>
                          </div>
                        </TableCell>
                      ) : null}
                    </BStyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12}>
          <div
            style={{
              display: 'flex',
              position: 'relative',
              marginBottom: '5px',
            }}
          >
            <div
              style={{
                flex: 1,
                marginLeft: '10px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <PLDeviceEventGroupStatus current_status={props.current_status} />
            </div>
            <div style={{ marginRight: '10px' }}>
              <BButton
                caption='Settings'
                clickEvent={() =>
                  props.gotoPatientRegistration(
                    'Settings',
                    props.patientId,
                    props.deviceSettingId,
                    props.registered
                  )
                }
              />
              <span className='space-right'></span>
              <BButton
                caption='ECG Viewer'
                id={generateID(
                  'btn',
                  'esumwin',
                  `ecg_viewer_${props.deviceEventId}`
                )}
                clickEvent={() => {
                  if (typeof window !== 'undefined') {
                    window.open(
                      formECGViewerURL(config.ecgApiEndpointUrl, props.deviceId)
                    );
                  }
                }}
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default PatientEventNotification;
