import {
  Dialog,
  DialogContent,
  Backdrop,
  Box,
  Typography,
} from '@mui/material';
import { BSpinnerReports } from '../../images/icons';

interface IBDialog {
  children?: React.ReactNode;
  style?: any;
  open?: boolean;
  setOpen?: any;
  message?: string;
}

const BDialog = (props: IBDialog) => {
  const {
    children,
    style,
    open,
    setOpen,
    message
  } = props;
  return (
    <>
      <Box sx={{ style }}>
        <Backdrop open={open} />
        <Dialog open={open} onClose={() => {
          setOpen(false);
        }} >
          <DialogContent>
            <Typography sx={{
              color: 'var(--grey-grey-900-text-color, #1A1C1C)',
              textAlign: 'center',
              fontFamily: 'Geogrotesque',
              fontSize: '26px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: '32px',
              width: '432px'
            }}>
              {message}
            </Typography>
            <Box display='flex' justifyContent='center' alignItems='center'>
              <BSpinnerReports sx={{ width: '48px', height: '48px', marginTop: '48px', marginBottom: '45px', flexShrink: 0 }}></BSpinnerReports>
            </Box>
          </DialogContent>
        </Dialog >
      </Box>
    </>
  );
}

export default BDialog;