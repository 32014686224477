import { useEffect, createContext, useContext, useState } from 'react';
import { ApolloError, useQuery } from '@apollo/client';
import { GET_USER_ROLE } from '../data/queries/login';
import { getFinalPermissions } from '../types/permissions';
import { Context } from 'react';
import {
  IUserAccessObject,
  IUserAccessPermissions,
  IUserAccessRole,
} from '../types/types';
import { useSendAuditTrailLogMessage } from '../data/queries/auditTrailLogMessage';

const PROFILE_MAP: { [k: string]: IUserAccessRole } = {
  Clinician: 'clinician',
  'System Admin': 'system_admin',
  'Organization Administrator': 'org_admin',
  'QC User': 'qc_user',
  'ECG Tech User': 'mctECGTech',
};

const defaultPermissions: IUserAccessObject = {
  canAccessTechNotes: false,
  canUpdatePatient: false,
  canUpdateSettings: false,
  canRegisterDevice: false,
  canLaunchECGViewer: false,
  navigationMenu: [],
  historyFilter: [],
  routeList: [],
};

type IAccessPermissionsContext = Context<{
  permissions: IUserAccessPermissions[];
  role: IUserAccessRole | null;
  token: string;
  access: IUserAccessObject;
  loading: boolean;
  error: ApolloError | false;
  name: string;
}>;

export const AccessPermissionsContext: IAccessPermissionsContext =
  createContext({
    permissions: null,
    token: null,
    role: null,
    access: defaultPermissions,
    loading: false,
    error: false,
    name: null,
  });

const AccessPermissionsProvider = ({ profileId, token, children }): any => {
  // TODO - probably merge this logic with useAcquireToken into one big Auth provider?
  const [permissions, setPermissions] = useState<IUserAccessPermissions[]>([]);
  const [role, setRole] = useState<IUserAccessRole | null>(null);
  const [access, setAccess] = useState(defaultPermissions);
  const [name, setName] = useState("");
  const { sendAuditMessage } = useSendAuditTrailLogMessage();

  const { data, error, loading } = useQuery(GET_USER_ROLE, {
    variables: {
      id: profileId,
    },
  });

  useEffect(() => {
    const newRole = PROFILE_MAP[data?.user?.profile?.name] || null;
    const newPermissions = data?.user?.permissions.map(p => p.name) || [];
    const accessObj = getFinalPermissions(newRole, newPermissions);
    const newName = `${data?.user?.first_name} ${data?.user?.last_name}`

    setRole(newRole);
    setPermissions(newPermissions);
    setAccess(accessObj);
    setName(newName);

    if (!data) return;
    sendAuditMessage({ action: "LOGIN", phi_flag: true,
      description: `User ${name} logged in with role ${role}`});
  }, [data]);
  if (error) {
    console.error('Error getting permissions:', error);
  }

  const value = {
    permissions,
    role,
    access,
    token,
    loading,
    error,
    name,
  };

  return (
    <AccessPermissionsContext.Provider value={value}>
      {children}
    </AccessPermissionsContext.Provider>
  );
};

export default AccessPermissionsProvider;
