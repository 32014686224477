export const HISTORY_NOTE = 'note';
export const D_HISTORY_NOTE = 'Technician Notes';
export const HISTORY_PROFILE = 'profile';
export const D_HISTORY_PROFILE = 'Profile Change';
export const HISTORY_SETTINGS = 'settings';
export const D_HISTORY_SETTINGS = 'Settings Change';
export const HISTORY_DEVICE = 'device';
export const D_HISTORY_DEVICE = 'Device Change';
export const HISTORY_EVENTS = 'events';
export const D_HISTORY_EVENTS = 'Event Notifications';
export const HISTORY_EVENT_REPORT = 'event_report';
export const D_HISTORY_EVENT_REPORT = 'Event Report';
export const HISTORY_SUMMARY_REPORT = 'summary_report';
export const HISTORY_CEMSUMMARY_REPORT = 'cemsummary_report';
export const HISTORY_EOS_REPORT = 'eos_report';
export const D_HISTORY_SUMMARY_REPORT = 'Summary Report';
export const D_HISTORY_CEMSUMMARY_REPORT = 'CEM Summary Report';
export const D_HISTORY_EOS_REPORT = 'EOS Report';
export const HISTORY_ALL_RECORDS = '0';
export const D_HISTORY_ALL_RECORDS = 'All Records';
export const DF_SDSDSD_C_DD_MMM = 'ddd, DD MMM';
export const DF_SDSDSD_C_DD_MMM_YYYY_SHSH_COL_SMSM_COL_SSSS_SA =
  'ddd, DD MMM YYYY hh:mm:ss a';
export const DF_DD_MMM_YYYY_HH_COL_SMSM = 'DD MMM YYYY HH:mm';
export const DD_MMM_C_YYYY = 'DD MMM, YYYY';
export const DD_MMM_YYYY = 'DD MMM YYYY';
export const YYYY_HYP_MM_HYP_DD = 'YYYY-MM-DD';
export const DF_DD_MMM_YYYY_SHSH_COL_SMSM_COL_SSSS_SA =
  'DD MMM YYYY hh:mm:ss a';
export const EMPTY_SELECT_OPTION = '';
export const PLEASE_SELECT = 'Please select';
export const APPLICATION_HOME = 'mail-to-patient';
export const APPLICATION_AT_CLINIC = 'clinic';
export const APPLICATION_AT_CLINIC_LABEL = 'At Clinic';
export const APPLICATION_HOME_LABEL = 'Home';
export const MODE_MCT = 'MCT';
export const MODE_MCT_LABEL = 'MCT';
export const MODE_LT_ECG = 'LT-ECG';
export const MODE_LT_ECG_LABEL = 'LT-ECG';
export const MODE_CEM_LABEL = 'CEM';
export const MODE_CEM = 'CEM';

export const RFC_DATA_ENTRY_ERROR_OPTION = 'Data entry error';
export const RFC_DATA_NOT_AVAILABLE_OPTION = 'Data was not available earlier';
export const RFC_INITIALLY_OMITTED_OPTION = 'Initially Omitted';

export const RFC_DATA_ENTRY_ERROR_LABEL = 'Data entry error';
export const RFC_DATA_NOT_AVAILABLE_LABEL = 'Data  was not available earlier';
export const RFC_INITIALLY_OMITTED_LABEL = 'Initially Omitted';

export enum REPORT_CHANGE_TYPE {
  Medication = 'Medication',
  Profile = 'Procedure',
}

export enum PATIENT_STEPS_TITLE {
  Profile = 0,
  Settings = 1,
  Device = 2,
  History = 3,
}

export interface IPatientListData {
  patientList: any;
  uniqueDeviceIdsArr: any;
  uniqueDeviceEventGroupIdsArr: any;
  totalCount: number;
}

export interface IPatient {
  patientId: string;
  deviceSettingId: string;
  deviceEventId: string; // unique id per device event
  firstName: string;
  lastName: string;
  reportCreatedAt: string;
  birthDate: string;
  age: string;
  sex: string;
  indications: any; // TODO change as per the patientEvent response
  physicianName: string; // TODO dont have any column yet in the patientEventbase
  prescribing_provider: string;
  referringProvider: string;
  patientIdentifier: number; // TODO check same as MRN?
  deviceId: string; // The ID that is added as the implant ID while registering the device,
  startTime: any;
  endTime: any;
  deviceEventsList: any;
  eventOrder: number;
}

export interface IBTextField {
  type?: string;
  placeholder?: string;
  disabled?: boolean;
  label?: string;
  name?: string;
  id?: string;
  value: string;
  required?: boolean;
  fullWidth?: boolean;
  handleChange?: any;
  handleBlur?: any;
  shrink?: any;
  errors?: any;
  touched?: any;
  style?: any;
  rows?: number;
  multiline?: boolean;
  className?: any;
  inputStyle?: any;
  defaultValue?: any;
  maxLength?: any;
  max?: any;
  tabIndex?: number;
  autoFocus?: boolean;
  step?: any;
  additionalProps?: any;
  inputClassName?: any;
  variant?: any;
  startAdornment?: any;
  size?: string;
}

export const PATIENT_REGISTRATION_TABS = {
  0: {
    id: 'profile',
    label: 'PROFILE',
  },
  1: {
    id: 'settings',
    label: 'SETTINGS',
  },
  2: {
    id: 'device',
    label: 'DEVICE',
  },
  3: {
    id: 'history',
    label: 'HISTORY',
  },
};

export const changeType = [
  REPORT_CHANGE_TYPE.Medication,
  REPORT_CHANGE_TYPE.Profile,
];
export const PHONE_MASK = '999-999-9999';

export const REPORT_OPTIONS = [
  { value: 'Medication', label: REPORT_CHANGE_TYPE.Medication },
  { value: 'Procedure', label: REPORT_CHANGE_TYPE.Profile },
];

export const FIRST_OCCURENCE_OPTIONS = [
  { value: '1', label: 'On' },
  { value: '0', label: 'Off' },
];

export const PATIENT_HISTORY_EVENT_MAPPING = {
  [HISTORY_ALL_RECORDS]: [D_HISTORY_ALL_RECORDS],
  [HISTORY_EVENTS]: [D_HISTORY_EVENTS],
  [HISTORY_NOTE]: [D_HISTORY_NOTE],
  [HISTORY_EVENT_REPORT]: [D_HISTORY_EVENT_REPORT],
  [HISTORY_SUMMARY_REPORT]: [D_HISTORY_SUMMARY_REPORT],
  [HISTORY_CEMSUMMARY_REPORT]: [D_HISTORY_CEMSUMMARY_REPORT],
  [HISTORY_EOS_REPORT]: [D_HISTORY_EOS_REPORT],
  [HISTORY_SETTINGS]: [D_HISTORY_SETTINGS],
  [HISTORY_PROFILE]: [D_HISTORY_PROFILE],
  [HISTORY_DEVICE]: [D_HISTORY_DEVICE],
};

export const PATIENT_HISTORY_EVENTS = [
  { value: HISTORY_ALL_RECORDS, label: D_HISTORY_ALL_RECORDS },
  { value: HISTORY_EVENTS, label: D_HISTORY_EVENTS },
  { value: HISTORY_NOTE, label: D_HISTORY_NOTE },
  { value: HISTORY_EVENT_REPORT, label: D_HISTORY_EVENT_REPORT },
  { value: HISTORY_SUMMARY_REPORT, label: D_HISTORY_SUMMARY_REPORT },
  { value: HISTORY_CEMSUMMARY_REPORT, label: D_HISTORY_CEMSUMMARY_REPORT },
  { value: HISTORY_SETTINGS, label: D_HISTORY_SETTINGS },
  { value: HISTORY_PROFILE, label: D_HISTORY_PROFILE },
  { value: HISTORY_DEVICE, label: D_HISTORY_DEVICE },
];

export const DEVICE_APPLICATION_OPTIONS = [
  { value: EMPTY_SELECT_OPTION, label: PLEASE_SELECT },
  { value: APPLICATION_AT_CLINIC, label: APPLICATION_AT_CLINIC_LABEL },
  { value: APPLICATION_HOME, label: APPLICATION_HOME_LABEL },
];

export const DEVICE_MODES = [
  { value: MODE_MCT, label: MODE_MCT_LABEL },
  { value: MODE_LT_ECG, label: MODE_LT_ECG_LABEL },
  { value: MODE_CEM, label: MODE_CEM_LABEL },
];

export const REASON_FOR_CHANGE_OPTIONS = [
  { value: EMPTY_SELECT_OPTION, label: PLEASE_SELECT },
  { value: RFC_DATA_ENTRY_ERROR_OPTION, label: RFC_DATA_ENTRY_ERROR_LABEL },
  { value: RFC_DATA_NOT_AVAILABLE_OPTION, label: RFC_DATA_NOT_AVAILABLE_LABEL },
  { value: RFC_INITIALLY_OMITTED_OPTION, label: RFC_INITIALLY_OMITTED_LABEL },
];

export const GENDER_OPTIONS = [
  {
    value: 'female',
    label: 'Female',
  },
  {
    value: 'male',
    label: 'Male',
  },
];

export enum Level {
  Off = 'OFF',
  Low = 'LOW',
  High = 'HIGH',
}

export enum MCT_Level {
  on = 'ON',
  Off = 'OFF',
}

export interface DurationSetting {
  name: string;
  length: string;
  min: number;
  max: number;
}

interface ThresholdValue {
  value: number;
  level: string;
}

export interface Threshold {
  id: string;
  threshold_id: string;
  name: string;
  value: number;
  min: number;
  max: number;
  threshold_value: Array<ThresholdValue>;
}

export interface Setting {
  id: string;
  setting_id: string;
  patient_id: number;
  abbreviation: string;
  thresholds: Array<Threshold>;
  name: string;
  level: number;
  duration?: DurationSetting;
}

export interface Patient {
  patientId: number;
  settings: Array<Setting>;
}

export interface DeviceEventProperty {
  name: string;
  value: string;
  type: string;
  order: Number;
}

// Patient List Header and body properties - Default
export const PATIENT_LIST_HEADER = [
  {
    uid: 'name',
    label: 'NAME',
    colLength: '200px',
    alignCell: 'left',
    align: 'left',
    id: 'name',
    field: 'lastname',
  },
  {
    uid: 'date_of_birth',
    label: 'BIRTH DATE',
    colLength: '150px',
    alignCell: 'left',
    align: 'left',
    id: '',
    field: '',
  },
  {
    uid: 'patient_ID',
    label: 'PATIENT ID/ MRN',
    colLength: '155px',
    alignCell: 'left',
    align: 'left',
    id: 'patientIdentifier',
    field: 'patient_identifier',
  },
  {
    uid: 'referring_provider',
    label: 'REFERRING PROVIDER',
    colLength: '200px',
    alignCell: 'left',
    align: 'left',
    id: '',
    field: '',
  },
  {
    uid: 'history',
    label: 'HISTORY',
    colLength: '70px',
    alignCell: 'center',
    align: 'center',
    id: '',
    field: '',
  },
  {
    uid: 'age_sex',
    label: 'AGE/SEX',
    colLength: '75px',
    alignCell: 'center',
    align: 'center',
    id: '',
    field: '',
  },
  {
    uid: 'indications',
    label: 'INDICATIONS',
    colLength: '',
    alignCell: 'left',
    align: 'left',
    id: '',
    field: '',
  },
  {
    uid: 'icm_ID',
    label: 'Device SN',
    colLength: '150px',
    alignCell: 'left',
    align: 'left',
    id: 'deviceId',
    field: 'id',
  },
];

// Event List Header and body properties - Default
export const PATIENT_EVENTS_LIST_HEADER = [
  {
    uid: 'device_event_group_status',
    label: 'Event Status',
    colLength: '250px',
    alignCell: 'center',
    align: 'center',
    id: 'current_status',
    field: 'currentStatus',
  },
  {
    uid: 'name',
    label: 'NAME',
    colLength: '200px',
    alignCell: 'left',
    align: 'left',
    id: 'name',
    field: 'lastname',
  },
  {
    uid: 'date_of_birth',
    label: 'BIRTH DATE',
    colLength: '150px',
    alignCell: 'left',
    align: 'left',
    id: '',
    field: '',
  },
  {
    uid: 'patient_ID',
    label: 'PATIENT ID/ MRN',
    colLength: '155px',
    alignCell: 'left',
    align: 'left',
    id: 'patientIdentifier',
    field: 'patient_identifier',
  },
  {
    uid: 'referring_provider',
    label: 'REFERRING PROVIDER',
    colLength: '200px',
    alignCell: 'left',
    align: 'left',
    id: '',
    field: '',
  },
  {
    uid: 'event_date',
    label: 'DATE',
    colLength: '150px',
    alignCell: 'left',
    align: 'left',
    id: 'endTimeTimestamp',
    field: '',
  },
  {
    uid: 'events',
    label: 'EVENTS',
    colLength: '390px',
    alignCell: 'left',
    align: 'center',
    id: 'eventOrder',
    field: '',
  },
  {
    uid: 'device_status',
    label: 'DEVICE',
    colLength: '100px',
    alignCell: 'left',
    align: 'center',
    id: 'deviceStatus',
    field: '',
  },
  {
    uid: 'viewer',
    label: 'VIEWER',
    colLength: '100px',
    alignCell: 'center',
    align: 'center',
    id: '',
    field: '',
  },
  {
    uid: 'history',
    label: 'HISTORY',
    colLength: '70px',
    alignCell: 'center',
    align: 'center',
    id: '',
    field: '',
  },
  {
    uid: 'age_sex',
    label: 'AGE/SEX',
    colLength: '75px',
    alignCell: 'center',
    align: 'center',
    id: '',
    field: '',
  },
  {
    uid: 'indications',
    label: 'INDICATIONS',
    colLength: '',
    alignCell: 'left',
    align: 'left',
    id: '',
    field: '',
  },
  {
    uid: 'icm_ID',
    label: 'Device SN',
    colLength: '150px',
    alignCell: 'left',
    align: 'left',
    id: 'deviceId',
    field: 'id',
  },
];

// Map uids to rendering components for Patient list
export const MAP_PLHEADING_COMPONENT = {
  review: 'PLReview',
  name: 'PLPatientName',
  date_of_birth: 'PLPatientIdMRN',
  patient_ID: 'PLPatientIdMRN',
  prescribing_provider: 'PLPrescribingProvider',
  event_date: 'PLEventDate',
  events: 'PLEvents',
  tviewer: 'PLViewer',
  history: 'PLHistory',
  age_sex: 'PLAgeSex',
  indications: 'PLIndications',
  icm_ID: 'PLICMID',
};

// Patient list columns that needs sorting
export const patientListSortCols = [
  'name',
  'patientIdentifier',
  'deviceId',
  'eventOrder',
  'endTimeTimestamp',
];

export const PATIENT_LIST_FILTER = [
  {
    value: 'unresolved',
    label: 'Unresolved',
  },
  {
    value: 'all',
    label: 'All Patients',
  },
];
// Settings List Header
export const THRESHOLD_SETTINGS_HEADER = [
  { label: '', mdSize: 1, xsSize: 3, className: 'threshold-list-header' },
  {
    label: 'Event',
    mdSize: 4,
    xsSize: 3,
    className: 'threshold-list-header',
  },
  {
    label: 'Notification Limit',
    mdSize: 3,
    xsSize: 5,
    className: 'threshold-list-header',
  },
  {
    label: 'Notifications on/off',
    mdSize: 3,
    xsSize: 2,
    className: 'threshold-list-header',
  },
];

export const THRESHOLD_SETTINGS_HEADER_REPORT = [
  { label: '', mdSize: 1, xsSize: 1, className: 'threshold-list-header' },
  { label: 'EVENT', mdSize: 2, xsSize: 2, className: 'threshold-list-header' },
  {
    label: 'NOTIFICATION LIMIT',
    mdSize: 4,
    xsSize: 5,
    className: 'threshold-list-header',
  },
  { label: 'LEVEL', mdSize: 2, xsSize: 2, className: 'threshold-list-header' },
  {
    label: 'REPORT LIMIT',
    mdSize: 3,
    xsSize: 2,
    className: 'threshold-list-header',
  },
];
// Report List Header
export const REPORT_TABLE_HEADER = [
  {
    label: 'Record Type',
    id: 'type',
    align: 'left',
    field: 'type',
    class: 'reportlist-type reportlist-type-header',
    colLength: '50px',
  },
  {
    label: 'Date and Time',
    id: 'createdAt',
    align: 'left',
    field: 'date',
    class: 'reportlist-date reportlist-date-header',
    colLength: '50px',
  },
  {
    label: 'User ID',
    id: '',
    align: 'left',
    field: '',
    class: 'reportlist-userid reportlist-userid-header',
    colLength: '50px',
  },
  {
    label: 'Details',
    id: '',
    align: 'left',
    field: 'notes',
    class: 'reportlist-desc reportlist-desc-header',
    colLength: '200px',
  },
];

// List of columns which are sortable => should match with id in the header
export const columnsSortHistory = ['type', 'createdAt'];

// Device
export const STATUS_DEVICE_INCOMPLETE = 0;
export const STATUS_DEVICE_PENDING = 1;
export const STATUS_DEVICE_ACTIVE = 2;
export const STATUS_DEVICE_DEACTIVATED = 3;

export const getDeviceStatusFromData = {
  Pending: STATUS_DEVICE_PENDING,
  Active: STATUS_DEVICE_ACTIVE,
  // 'Active, Battery Level, Data Transmission': STATUS_DEVICE_ACTIVE,
  // 'Active, Data Transmission': STATUS_DEVICE_ACTIVE,
  // 'Active, Battery Level': STATUS_DEVICE_ACTIVE,
  Incomplete: STATUS_DEVICE_INCOMPLETE,
  Deactivated: STATUS_DEVICE_DEACTIVATED,
};

export const deviceActivationMsges = {
  '-1': {
    msg: 'Retrieving Status...',
    severity: 'warning',
  },
  [STATUS_DEVICE_PENDING]: {
    msg: 'ICM device has been successfully activated', //customized msg
    severity: 'success',
  },
  [STATUS_DEVICE_INCOMPLETE]: {
    msg: 'You need to complete registration of the device', //customized msg
    severity: 'warning',
  },
  [STATUS_DEVICE_ACTIVE]: {
    msg: 'ICM device is activated. Show the live ECG to verify the waveform is acceptable',
    severity: 'info',
  },
};

// contants for device types
export const DEVICE_TYPE_ICM = 'ICM';
export const DEVICE_TYPE_MCT = 'MCT';
// evnt types for patient list
export const EVENT_TYPE_CLINICAL = 'clinical';
export const EVENT_TYPE_STATUS = 'device_status';
export const EVENT_TYPE_REPORT = 'report';

// mapping for db filtering
export const plColToDBFilterMapping = {
  name: ['last_name', 'first_name'],
  patient_identifier: ['patient_identifier'],
  age_sex: [], // make this unsortable, unfilterable
  date_of_birth: ['date_of_birth'],
  tmct_ID: ['devices.deviceId'],
  indications: ['indications.desc'],
  referring_provider: ['devices.referring_provider'],
  tviewer_due: [
    'devices.reports.reportType.name',
    'devices.reports.current_state',
  ],
  history: ['devices.reports.reportType.name', 'devices.reports.current_state'],
  tdevice_status: ['patientNewestDeviceView.device_status_with_flags'],
  technician_notes: ['technician_notes.note'],
  organization: ['devices.patients.organization.name'],
};

// get from PS
export const elColToDBFilterMapping = {
  name: ['device.patients.last_name', 'device.patients.first_name'],
  patient_identifier: ['device.patients.patient_identifier'],
  age_sex: ['device.patients.date_of_birth'], // We'd need DOB here as the filter unless we want to save a whole separate age column which seems cumbersome
  date_of_birth: ['device.patients.date_of_birth'],
  tmct_ID: ['device.deviceId'], // This is the device ID as the ECG backend uses it
  indications: ['device.patients.indications.desc'], // code is the abbreviated one (i.e. R002)
  referring_provider: ['device.referring_provider'],
  tevents: ['deviceEvents.deviceEventSetting.deviceEventSettingType.abbr'], // clinical events
  tevent_date: ['end_time'],
  notification_date: ['created_at'],
  current_status: ['currentStatus.name'], // called 'status' to the user
  tdevice_status: ['device.deviceStatusWithFlagsView.device_status_with_flags'],
  tviewer: ['display_order'],
  history: ['device.reports.reportType.name', 'device.reports.current_state'],
  organization: ['device.patients.organization.name'],
  technician_notes: ['device.patients.technician_notes.note'],
};

export const filterKeyMap = {
  PATIENT: plColToDBFilterMapping,
  EVENT: elColToDBFilterMapping,
};

// mapping for db filtering
export const plColToDBSortMapping = {
  name: ['last_name', 'first_name'],
  patient_identifier: ['patient_identifier'],
  age_sex: [], // make this unsortable, unfilterable
  date_of_birth: ['date_of_birth'],
  tmct_ID: ['devices.deviceId'],
  indications: ['indications.desc'],
  referring_provider: ['devices.referring_provider'],
  history: ['devices.reports.updated_at'],
  tviewer_due: ['devices.reports.updated_at'],
  tdevice_status: ['patientNewestDeviceView.device_status_with_flags'],
  organization: ['organization.name'],
};

export const elColToDBSortMapping = {
  name: ['device.patients.last_name', 'device.patients.first_name'],
  patient_identifier: ['device.patients.patient_identifier'],
  age_sex: ['device.patients.date_of_birth'], // We'd need DOB here as the filter unless we want to save a whole separate age column which seems cumbersome
  date_of_birth: ['device.patients.date_of_birth'],
  tmct_ID: ['device.deviceId'], // This is the device ID as the ECG backend uses it
  indications: ['device.patients.indications.desc'], // code is the abbreviated one (i.e. R002)
  referring_provider: ['device.referring_provider'],
  current_status: ['currentStatus.display_order'], // also called 'status' to the user
  tevents: ['display_order'], // clinical events
  tevent_date: ['end_time'],
  notification_date: ['created_at'],
  tdevice_status: ['device.deviceStatusWithFlagsView.device_status_with_flags'],
  tviewer: ['display_order'], // this errors - need to define type as multiselect
  history: ['device.reports.updated_at'],
  organization: ['device.patients.organization.name'],
};

export interface IView {
  id: string;
  name: string;
  default: string;
}

export type IGetAllView = {
  loading?: any;
  error?: any;
  data: {
    views: IView[];
  };
};
// TODO only use patientDetails.patient from API calls, and update this so that we don't pass a nested object everywhere
export interface IPatientDetails {
  patient: {
    last_name: string;
    first_name: string;
    patient_identifier: string;
    age: number;
    sex: string;
    history: any;
    indications: any;
    devices?: any;
  };
}

// User access
export type IUserAccessPermissions =
  | 'MCTTechnicianAccess'
  | 'ClinicianAccess'

export type IUserAccessRole =
  | 'clinician'
  | 'distributor_admin'
  | 'system_admin'
  | 'org_admin'
  | 'qc_user'
  | 'mctECGTech';

export interface IUserAccessObject {
  canAccessTechNotes: boolean,
  canUpdatePatient: boolean,
  canUpdateSettings: boolean,
  canRegisterDevice: boolean,
  canLaunchECGViewer: boolean,
  navigationMenu: any[],
  historyFilter: any[],
  routeList: JSX.Element[],
}
export interface ISetting {
  id: string;
  settingType: ISettingType;
  thresholds: IThreshold[];
  severity: 'on' | 'off';
  threshold_report?: any;
}

export interface ISettingType {
  abbr: string;
  abbr_type: string;
  display_order: number;
  id: string;
  name: string;
}

export interface IThreshold {
  id: string;
  thresholdType: any;
  value: number;
}

export interface ISettingGroup {
  id: string;
  name: string;
  organization_default: boolean;
  global_default: boolean;
}

export interface IDropdownItem {
  label: string;
  value: string;
  grayed?: boolean;
  isDefault?: boolean;
}

export interface ISettingNameMapping {
  thresholds: ISettingNames;
  settings: ISettingNames;
}

export interface ISettingNames {
  [key: string]: [abbr: string, name: string, thresholdTypeName?: string];
}

export interface IDirtyField {
  setting_abbr: string;
  name: string;
  initialValue: string | number;
  value: string | number;
}
