import gql from 'graphql-tag';

export const REPORTS = gql`
  query getPatientHistory($patient_id: String!) {
    patient(id: $patient_id) {
      history(orderByDesc: created_at) {
        id
        type
        description
        created_at
        pinned_order
      }
      devices {
        deviceId
      }
    }
  }
`;
/**
 * @desc Add new report for the patient with id patientId
 */
export const ADD_REPORT = gql`
  mutation createReport(
    $type: String!
    $description: String!
    $patientId: String!
    $created_at: String
  ) {
    createReport(
      type: $type
      patientId: $patientId
      description: $description
      created_at: $created_at
      updated_at: $created_at
    ) {
      id
      type
      description
      patientId
      created_at
      updated_at
    }
  }
`;


export const GET_REPORT_DATA = gql`
  query getReportData($reportId: String!) {
    report(id: $reportId) {
      id
      file_link
      reportType {
        name
      }
      device {
        id
        deviceId
        patients {
          id
          first_name
          last_name
          patient_identifier
        }
        prescribing_provider
      }
      approved_by
      approved_at
      approval_status
      approver {
        id
        first_name
        last_name
      }
    }
  }
`;

export const GET_REPORT_LINK = gql`
  mutation getReportLink($reportFilename: String!) {
    getReportLink(reportFilename: $reportFilename)
  }
`;

export const SET_REPORT_VIEWED = gql`
  mutation setReportViewed($reportId: String!) {
    setReportViewed(reportId: $reportId) {
      id
    }
  }
`;

export const APPROVE_REPORT = gql`
  mutation approveReport(
    $reportId: String!,
    $reportType: String!,
    $interpretationText: String!,
    $deviceId: String!,
    $reasonForChange: String
  ) {
    approveReport(
      parentReportId: $reportId,
      reportType: $reportType,
      interpretationText: $interpretationText,
      deviceId: $deviceId,
      reasonForChange: $reasonForChange
    ) {
      id
    }
  }
`;


export const PENDING_REPORT_REQUESTS = gql`
  query pendingReportRequests(
    $reportId: String!,
  ) {
    reportRequests(
      parent_report_id: $reportId,
      report_generation_status: pending
    ) {
      id
      source
      report_generation_status
      reportType {
        name
      }
    }
  }
`
