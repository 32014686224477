import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
  split,
  HttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';
import { typeDefs, resolvers } from './../utility/ClientUtility';
import { silentRequest } from '../auth/msalConfig';
import { InteractionRequiredAuthError } from '@azure/msal-browser';

export const createApolloClient = (apiEndpointUrl: string, accessToken: string, msal, env: string): ApolloClient<NormalizedCacheObject> => {

  // Apollo client configuration

  const protocol = env === 'local' ? 'http' : 'https'
  const httpLink = new HttpLink({
    uri: `${protocol}://${apiEndpointUrl}`
  });

  const wsLink = new GraphQLWsLink(
    createClient({
      url: `wss://${apiEndpointUrl}`,
    })
  );

  const authLink = setContext(async (_, { headers }) => {
    const account = msal.instance.getActiveAccount();
    if (!account) {
      throw new Error('No active account');
    }

    let accessToken;
    try {
      const { accessToken: token } = await msal.instance.acquireTokenSilent({
        scopes: silentRequest.scopes,
        account,
      });
      accessToken = token;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        const { accessToken: token } = await msal.instance.acquireTokenRedirect({
          scopes: silentRequest.scopes,
        });
        accessToken = token;
      } else {
        throw error;
      }
    }

    return {
      headers: {
        ...headers,
        authorization: `Bearer ${accessToken}`,
      },
    };
  });

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    authLink.concat(httpLink)
  );

  return new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache({
      addTypename: false, // to avoid __typename being added to the queries
    }),
    typeDefs,
    resolvers,
  });
};
